/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Fintecy WealthManager API
 * API for Fintecy WealthManager application
 * OpenAPI spec version: 1.0.0
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  Account,
  Document,
  GetAuthSessions200Item,
  GetDocumentsFolders200Item,
  GetMarketAssetsAssetIdPriceHistory200Item,
  GetMarketAssetsParams,
  GetSettingsCountries200Item,
  GetSettingsFilingStatuses200Item,
  LoginRequest,
  LoginResponse,
  MarketAsset,
  PersonalInfo,
  PostAccountsBody,
  Preferences,
  PutAccountsIdBody,
  PutAuthPasswordBody,
  SecuritySettings,
  TaxSettings,
  Transaction,
} from './model';

import { customInstance } from '../axios-instance';

/**
 * @summary Get all accounts
 */
export const getAccounts = (signal?: AbortSignal) => {
  return customInstance<Account[]>({ url: `/accounts`, method: 'GET', signal });
};

export const getGetAccountsQueryKey = () => {
  return [`/accounts`] as const;
};

export const getGetAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccounts>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccounts>>> = ({ signal }) =>
    getAccounts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccounts>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccounts>>>;
export type GetAccountsQueryError = unknown;

export function useGetAccounts<
  TData = Awaited<ReturnType<typeof getAccounts>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getAccounts>>,
        TError,
        Awaited<ReturnType<typeof getAccounts>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetAccounts<
  TData = Awaited<ReturnType<typeof getAccounts>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getAccounts>>,
        TError,
        Awaited<ReturnType<typeof getAccounts>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetAccounts<
  TData = Awaited<ReturnType<typeof getAccounts>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get all accounts
 */

export function useGetAccounts<
  TData = Awaited<ReturnType<typeof getAccounts>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetAccountsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a new account
 */
export const postAccounts = (postAccountsBody: PostAccountsBody, signal?: AbortSignal) => {
  return customInstance<Account>({
    url: `/accounts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postAccountsBody,
    signal,
  });
};

export const getPostAccountsMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAccounts>>,
    TError,
    { data: PostAccountsBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAccounts>>,
  TError,
  { data: PostAccountsBody },
  TContext
> => {
  const mutationKey = ['postAccounts'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAccounts>>,
    { data: PostAccountsBody }
  > = (props) => {
    const { data } = props ?? {};

    return postAccounts(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAccountsMutationResult = NonNullable<Awaited<ReturnType<typeof postAccounts>>>;
export type PostAccountsMutationBody = PostAccountsBody;
export type PostAccountsMutationError = void;

/**
 * @summary Create a new account
 */
export const usePostAccounts = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAccounts>>,
    TError,
    { data: PostAccountsBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAccounts>>,
  TError,
  { data: PostAccountsBody },
  TContext
> => {
  const mutationOptions = getPostAccountsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update an account
 */
export const putAccountsId = (id: string, putAccountsIdBody: PutAccountsIdBody) => {
  return customInstance<Account>({
    url: `/accounts/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: putAccountsIdBody,
  });
};

export const getPutAccountsIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAccountsId>>,
    TError,
    { id: string; data: PutAccountsIdBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putAccountsId>>,
  TError,
  { id: string; data: PutAccountsIdBody },
  TContext
> => {
  const mutationKey = ['putAccountsId'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putAccountsId>>,
    { id: string; data: PutAccountsIdBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return putAccountsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutAccountsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putAccountsId>>>;
export type PutAccountsIdMutationBody = PutAccountsIdBody;
export type PutAccountsIdMutationError = void;

/**
 * @summary Update an account
 */
export const usePutAccountsId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAccountsId>>,
    TError,
    { id: string; data: PutAccountsIdBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putAccountsId>>,
  TError,
  { id: string; data: PutAccountsIdBody },
  TContext
> => {
  const mutationOptions = getPutAccountsIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete an account
 */
export const deleteAccountsId = (id: string) => {
  return customInstance<void>({ url: `/accounts/${id}`, method: 'DELETE' });
};

export const getDeleteAccountsIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAccountsId>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAccountsId>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationKey = ['deleteAccountsId'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAccountsId>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteAccountsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAccountsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAccountsId>>
>;

export type DeleteAccountsIdMutationError = void;

/**
 * @summary Delete an account
 */
export const useDeleteAccountsId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAccountsId>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAccountsId>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteAccountsIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Refresh account balance
 */
export const postAccountsIdRefresh = (id: string, signal?: AbortSignal) => {
  return customInstance<Account>({ url: `/accounts/${id}/refresh`, method: 'POST', signal });
};

export const getPostAccountsIdRefreshMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAccountsIdRefresh>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAccountsIdRefresh>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationKey = ['postAccountsIdRefresh'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAccountsIdRefresh>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return postAccountsIdRefresh(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAccountsIdRefreshMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAccountsIdRefresh>>
>;

export type PostAccountsIdRefreshMutationError = void;

/**
 * @summary Refresh account balance
 */
export const usePostAccountsIdRefresh = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAccountsIdRefresh>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAccountsIdRefresh>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getPostAccountsIdRefreshMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get all documents
 */
export const getDocuments = (signal?: AbortSignal) => {
  return customInstance<Document[]>({ url: `/documents`, method: 'GET', signal });
};

export const getGetDocumentsQueryKey = () => {
  return [`/documents`] as const;
};

export const getGetDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocuments>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocuments>>> = ({ signal }) =>
    getDocuments(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocuments>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getDocuments>>>;
export type GetDocumentsQueryError = unknown;

export function useGetDocuments<
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocuments>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getDocuments>>,
        TError,
        Awaited<ReturnType<typeof getDocuments>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetDocuments<
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocuments>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getDocuments>>,
        TError,
        Awaited<ReturnType<typeof getDocuments>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetDocuments<
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocuments>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get all documents
 */

export function useGetDocuments<
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocuments>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetDocumentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all document folders
 */
export const getDocumentsFolders = (signal?: AbortSignal) => {
  return customInstance<GetDocumentsFolders200Item[]>({
    url: `/documents/folders`,
    method: 'GET',
    signal,
  });
};

export const getGetDocumentsFoldersQueryKey = () => {
  return [`/documents/folders`] as const;
};

export const getGetDocumentsFoldersQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentsFolders>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentsFolders>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentsFoldersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentsFolders>>> = ({ signal }) =>
    getDocumentsFolders(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentsFolders>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetDocumentsFoldersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentsFolders>>
>;
export type GetDocumentsFoldersQueryError = unknown;

export function useGetDocumentsFolders<
  TData = Awaited<ReturnType<typeof getDocumentsFolders>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentsFolders>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getDocumentsFolders>>,
        TError,
        Awaited<ReturnType<typeof getDocumentsFolders>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetDocumentsFolders<
  TData = Awaited<ReturnType<typeof getDocumentsFolders>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentsFolders>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getDocumentsFolders>>,
        TError,
        Awaited<ReturnType<typeof getDocumentsFolders>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetDocumentsFolders<
  TData = Awaited<ReturnType<typeof getDocumentsFolders>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentsFolders>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get all document folders
 */

export function useGetDocumentsFolders<
  TData = Awaited<ReturnType<typeof getDocumentsFolders>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentsFolders>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetDocumentsFoldersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all transactions
 */
export const getTransactions = (signal?: AbortSignal) => {
  return customInstance<Transaction[]>({ url: `/transactions`, method: 'GET', signal });
};

export const getGetTransactionsQueryKey = () => {
  return [`/transactions`] as const;
};

export const getGetTransactionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactions>>> = ({ signal }) =>
    getTransactions(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransactions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactions>>>;
export type GetTransactionsQueryError = unknown;

export function useGetTransactions<
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getTransactions>>,
        TError,
        Awaited<ReturnType<typeof getTransactions>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetTransactions<
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getTransactions>>,
        TError,
        Awaited<ReturnType<typeof getTransactions>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetTransactions<
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get all transactions
 */

export function useGetTransactions<
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetTransactionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get market assets
 */
export const getMarketAssets = (params?: GetMarketAssetsParams, signal?: AbortSignal) => {
  return customInstance<MarketAsset[]>({ url: `/market/assets`, method: 'GET', params, signal });
};

export const getGetMarketAssetsQueryKey = (params?: GetMarketAssetsParams) => {
  return [`/market/assets`, ...(params ? [params] : [])] as const;
};

export const getGetMarketAssetsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMarketAssets>>,
  TError = unknown,
>(
  params?: GetMarketAssetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketAssets>>, TError, TData>>;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMarketAssetsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketAssets>>> = ({ signal }) =>
    getMarketAssets(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMarketAssets>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetMarketAssetsQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketAssets>>>;
export type GetMarketAssetsQueryError = unknown;

export function useGetMarketAssets<
  TData = Awaited<ReturnType<typeof getMarketAssets>>,
  TError = unknown,
>(
  params: undefined | GetMarketAssetsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketAssets>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketAssets>>,
          TError,
          Awaited<ReturnType<typeof getMarketAssets>>
        >,
        'initialData'
      >;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetMarketAssets<
  TData = Awaited<ReturnType<typeof getMarketAssets>>,
  TError = unknown,
>(
  params?: GetMarketAssetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketAssets>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketAssets>>,
          TError,
          Awaited<ReturnType<typeof getMarketAssets>>
        >,
        'initialData'
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetMarketAssets<
  TData = Awaited<ReturnType<typeof getMarketAssets>>,
  TError = unknown,
>(
  params?: GetMarketAssetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketAssets>>, TError, TData>>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get market assets
 */

export function useGetMarketAssets<
  TData = Awaited<ReturnType<typeof getMarketAssets>>,
  TError = unknown,
>(
  params?: GetMarketAssetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketAssets>>, TError, TData>>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetMarketAssetsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get personal settings
 */
export const getSettingsPersonal = (signal?: AbortSignal) => {
  return customInstance<PersonalInfo>({ url: `/settings/personal`, method: 'GET', signal });
};

export const getGetSettingsPersonalQueryKey = () => {
  return [`/settings/personal`] as const;
};

export const getGetSettingsPersonalQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettingsPersonal>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsPersonal>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsPersonalQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsPersonal>>> = ({ signal }) =>
    getSettingsPersonal(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSettingsPersonal>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetSettingsPersonalQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSettingsPersonal>>
>;
export type GetSettingsPersonalQueryError = unknown;

export function useGetSettingsPersonal<
  TData = Awaited<ReturnType<typeof getSettingsPersonal>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsPersonal>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsPersonal>>,
        TError,
        Awaited<ReturnType<typeof getSettingsPersonal>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsPersonal<
  TData = Awaited<ReturnType<typeof getSettingsPersonal>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsPersonal>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsPersonal>>,
        TError,
        Awaited<ReturnType<typeof getSettingsPersonal>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsPersonal<
  TData = Awaited<ReturnType<typeof getSettingsPersonal>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsPersonal>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get personal settings
 */

export function useGetSettingsPersonal<
  TData = Awaited<ReturnType<typeof getSettingsPersonal>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsPersonal>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetSettingsPersonalQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update personal settings
 */
export const putSettingsPersonal = (personalInfo: PersonalInfo) => {
  return customInstance<PersonalInfo>({
    url: `/settings/personal`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: personalInfo,
  });
};

export const getPutSettingsPersonalMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSettingsPersonal>>,
    TError,
    { data: PersonalInfo },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSettingsPersonal>>,
  TError,
  { data: PersonalInfo },
  TContext
> => {
  const mutationKey = ['putSettingsPersonal'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSettingsPersonal>>,
    { data: PersonalInfo }
  > = (props) => {
    const { data } = props ?? {};

    return putSettingsPersonal(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSettingsPersonalMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSettingsPersonal>>
>;
export type PutSettingsPersonalMutationBody = PersonalInfo;
export type PutSettingsPersonalMutationError = void;

/**
 * @summary Update personal settings
 */
export const usePutSettingsPersonal = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSettingsPersonal>>,
    TError,
    { data: PersonalInfo },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putSettingsPersonal>>,
  TError,
  { data: PersonalInfo },
  TContext
> => {
  const mutationOptions = getPutSettingsPersonalMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get tax settings
 */
export const getSettingsTax = (signal?: AbortSignal) => {
  return customInstance<TaxSettings>({ url: `/settings/tax`, method: 'GET', signal });
};

export const getGetSettingsTaxQueryKey = () => {
  return [`/settings/tax`] as const;
};

export const getGetSettingsTaxQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettingsTax>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsTax>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsTaxQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsTax>>> = ({ signal }) =>
    getSettingsTax(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSettingsTax>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetSettingsTaxQueryResult = NonNullable<Awaited<ReturnType<typeof getSettingsTax>>>;
export type GetSettingsTaxQueryError = unknown;

export function useGetSettingsTax<
  TData = Awaited<ReturnType<typeof getSettingsTax>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsTax>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsTax>>,
        TError,
        Awaited<ReturnType<typeof getSettingsTax>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsTax<
  TData = Awaited<ReturnType<typeof getSettingsTax>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsTax>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsTax>>,
        TError,
        Awaited<ReturnType<typeof getSettingsTax>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsTax<
  TData = Awaited<ReturnType<typeof getSettingsTax>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsTax>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get tax settings
 */

export function useGetSettingsTax<
  TData = Awaited<ReturnType<typeof getSettingsTax>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsTax>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetSettingsTaxQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update tax settings
 */
export const putSettingsTax = (taxSettings: TaxSettings) => {
  return customInstance<TaxSettings>({
    url: `/settings/tax`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: taxSettings,
  });
};

export const getPutSettingsTaxMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSettingsTax>>,
    TError,
    { data: TaxSettings },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSettingsTax>>,
  TError,
  { data: TaxSettings },
  TContext
> => {
  const mutationKey = ['putSettingsTax'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSettingsTax>>,
    { data: TaxSettings }
  > = (props) => {
    const { data } = props ?? {};

    return putSettingsTax(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSettingsTaxMutationResult = NonNullable<Awaited<ReturnType<typeof putSettingsTax>>>;
export type PutSettingsTaxMutationBody = TaxSettings;
export type PutSettingsTaxMutationError = void;

/**
 * @summary Update tax settings
 */
export const usePutSettingsTax = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSettingsTax>>,
    TError,
    { data: TaxSettings },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putSettingsTax>>,
  TError,
  { data: TaxSettings },
  TContext
> => {
  const mutationOptions = getPutSettingsTaxMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get user preferences
 */
export const getSettingsPreferences = (signal?: AbortSignal) => {
  return customInstance<Preferences>({ url: `/settings/preferences`, method: 'GET', signal });
};

export const getGetSettingsPreferencesQueryKey = () => {
  return [`/settings/preferences`] as const;
};

export const getGetSettingsPreferencesQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettingsPreferences>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferences>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsPreferencesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsPreferences>>> = ({ signal }) =>
    getSettingsPreferences(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSettingsPreferences>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetSettingsPreferencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSettingsPreferences>>
>;
export type GetSettingsPreferencesQueryError = unknown;

export function useGetSettingsPreferences<
  TData = Awaited<ReturnType<typeof getSettingsPreferences>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferences>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsPreferences>>,
        TError,
        Awaited<ReturnType<typeof getSettingsPreferences>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsPreferences<
  TData = Awaited<ReturnType<typeof getSettingsPreferences>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferences>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsPreferences>>,
        TError,
        Awaited<ReturnType<typeof getSettingsPreferences>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsPreferences<
  TData = Awaited<ReturnType<typeof getSettingsPreferences>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferences>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get user preferences
 */

export function useGetSettingsPreferences<
  TData = Awaited<ReturnType<typeof getSettingsPreferences>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferences>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetSettingsPreferencesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update user preferences
 */
export const putSettingsPreferences = (preferences: Preferences) => {
  return customInstance<Preferences>({
    url: `/settings/preferences`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: preferences,
  });
};

export const getPutSettingsPreferencesMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSettingsPreferences>>,
    TError,
    { data: Preferences },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSettingsPreferences>>,
  TError,
  { data: Preferences },
  TContext
> => {
  const mutationKey = ['putSettingsPreferences'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSettingsPreferences>>,
    { data: Preferences }
  > = (props) => {
    const { data } = props ?? {};

    return putSettingsPreferences(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSettingsPreferencesMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSettingsPreferences>>
>;
export type PutSettingsPreferencesMutationBody = Preferences;
export type PutSettingsPreferencesMutationError = void;

/**
 * @summary Update user preferences
 */
export const usePutSettingsPreferences = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSettingsPreferences>>,
    TError,
    { data: Preferences },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putSettingsPreferences>>,
  TError,
  { data: Preferences },
  TContext
> => {
  const mutationOptions = getPutSettingsPreferencesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get preferences from local storage
 */
export const getSettingsPreferencesStorage = (signal?: AbortSignal) => {
  return customInstance<Preferences>({
    url: `/settings/preferences/storage`,
    method: 'GET',
    signal,
  });
};

export const getGetSettingsPreferencesStorageQueryKey = () => {
  return [`/settings/preferences/storage`] as const;
};

export const getGetSettingsPreferencesStorageQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettingsPreferencesStorage>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferencesStorage>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsPreferencesStorageQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsPreferencesStorage>>> = ({
    signal,
  }) => getSettingsPreferencesStorage(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSettingsPreferencesStorage>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetSettingsPreferencesStorageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSettingsPreferencesStorage>>
>;
export type GetSettingsPreferencesStorageQueryError = void;

export function useGetSettingsPreferencesStorage<
  TData = Awaited<ReturnType<typeof getSettingsPreferencesStorage>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferencesStorage>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsPreferencesStorage>>,
        TError,
        Awaited<ReturnType<typeof getSettingsPreferencesStorage>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsPreferencesStorage<
  TData = Awaited<ReturnType<typeof getSettingsPreferencesStorage>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferencesStorage>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsPreferencesStorage>>,
        TError,
        Awaited<ReturnType<typeof getSettingsPreferencesStorage>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsPreferencesStorage<
  TData = Awaited<ReturnType<typeof getSettingsPreferencesStorage>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferencesStorage>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get preferences from local storage
 */

export function useGetSettingsPreferencesStorage<
  TData = Awaited<ReturnType<typeof getSettingsPreferencesStorage>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsPreferencesStorage>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetSettingsPreferencesStorageQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Save preferences to local storage
 */
export const postSettingsPreferencesStorage = (preferences: Preferences, signal?: AbortSignal) => {
  return customInstance<void>({
    url: `/settings/preferences/storage`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: preferences,
    signal,
  });
};

export const getPostSettingsPreferencesStorageMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSettingsPreferencesStorage>>,
    TError,
    { data: Preferences },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSettingsPreferencesStorage>>,
  TError,
  { data: Preferences },
  TContext
> => {
  const mutationKey = ['postSettingsPreferencesStorage'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSettingsPreferencesStorage>>,
    { data: Preferences }
  > = (props) => {
    const { data } = props ?? {};

    return postSettingsPreferencesStorage(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSettingsPreferencesStorageMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSettingsPreferencesStorage>>
>;
export type PostSettingsPreferencesStorageMutationBody = Preferences;
export type PostSettingsPreferencesStorageMutationError = void;

/**
 * @summary Save preferences to local storage
 */
export const usePostSettingsPreferencesStorage = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSettingsPreferencesStorage>>,
    TError,
    { data: Preferences },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postSettingsPreferencesStorage>>,
  TError,
  { data: Preferences },
  TContext
> => {
  const mutationOptions = getPostSettingsPreferencesStorageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Clear preferences from local storage
 */
export const deleteSettingsPreferencesStorage = () => {
  return customInstance<void>({ url: `/settings/preferences/storage`, method: 'DELETE' });
};

export const getDeleteSettingsPreferencesStorageMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSettingsPreferencesStorage>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSettingsPreferencesStorage>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['deleteSettingsPreferencesStorage'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSettingsPreferencesStorage>>,
    void
  > = () => {
    return deleteSettingsPreferencesStorage();
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSettingsPreferencesStorageMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSettingsPreferencesStorage>>
>;

export type DeleteSettingsPreferencesStorageMutationError = unknown;

/**
 * @summary Clear preferences from local storage
 */
export const useDeleteSettingsPreferencesStorage = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSettingsPreferencesStorage>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteSettingsPreferencesStorage>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeleteSettingsPreferencesStorageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get security settings
 */
export const getSettingsSecurity = (signal?: AbortSignal) => {
  return customInstance<SecuritySettings>({ url: `/settings/security`, method: 'GET', signal });
};

export const getGetSettingsSecurityQueryKey = () => {
  return [`/settings/security`] as const;
};

export const getGetSettingsSecurityQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettingsSecurity>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsSecurity>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsSecurityQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsSecurity>>> = ({ signal }) =>
    getSettingsSecurity(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSettingsSecurity>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetSettingsSecurityQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSettingsSecurity>>
>;
export type GetSettingsSecurityQueryError = unknown;

export function useGetSettingsSecurity<
  TData = Awaited<ReturnType<typeof getSettingsSecurity>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsSecurity>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsSecurity>>,
        TError,
        Awaited<ReturnType<typeof getSettingsSecurity>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsSecurity<
  TData = Awaited<ReturnType<typeof getSettingsSecurity>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsSecurity>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsSecurity>>,
        TError,
        Awaited<ReturnType<typeof getSettingsSecurity>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsSecurity<
  TData = Awaited<ReturnType<typeof getSettingsSecurity>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsSecurity>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get security settings
 */

export function useGetSettingsSecurity<
  TData = Awaited<ReturnType<typeof getSettingsSecurity>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsSecurity>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetSettingsSecurityQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update security settings
 */
export const putSettingsSecurity = (securitySettings: SecuritySettings) => {
  return customInstance<SecuritySettings>({
    url: `/settings/security`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: securitySettings,
  });
};

export const getPutSettingsSecurityMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSettingsSecurity>>,
    TError,
    { data: SecuritySettings },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSettingsSecurity>>,
  TError,
  { data: SecuritySettings },
  TContext
> => {
  const mutationKey = ['putSettingsSecurity'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSettingsSecurity>>,
    { data: SecuritySettings }
  > = (props) => {
    const { data } = props ?? {};

    return putSettingsSecurity(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSettingsSecurityMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSettingsSecurity>>
>;
export type PutSettingsSecurityMutationBody = SecuritySettings;
export type PutSettingsSecurityMutationError = void;

/**
 * @summary Update security settings
 */
export const usePutSettingsSecurity = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSettingsSecurity>>,
    TError,
    { data: SecuritySettings },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putSettingsSecurity>>,
  TError,
  { data: SecuritySettings },
  TContext
> => {
  const mutationOptions = getPutSettingsSecurityMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Authenticate user and return JWT token
 */
export const postAuthLogin = (loginRequest: LoginRequest, signal?: AbortSignal) => {
  return customInstance<LoginResponse>({
    url: `/auth/login`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: loginRequest,
    signal,
  });
};

export const getPostAuthLoginMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthLogin>>,
    TError,
    { data: LoginRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthLogin>>,
  TError,
  { data: LoginRequest },
  TContext
> => {
  const mutationKey = ['postAuthLogin'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthLogin>>,
    { data: LoginRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postAuthLogin>>>;
export type PostAuthLoginMutationBody = LoginRequest;
export type PostAuthLoginMutationError = void;

/**
 * @summary Authenticate user and return JWT token
 */
export const usePostAuthLogin = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthLogin>>,
    TError,
    { data: LoginRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAuthLogin>>,
  TError,
  { data: LoginRequest },
  TContext
> => {
  const mutationOptions = getPostAuthLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update user password
 */
export const putAuthPassword = (putAuthPasswordBody: PutAuthPasswordBody) => {
  return customInstance<void>({
    url: `/auth/password`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: putAuthPasswordBody,
  });
};

export const getPutAuthPasswordMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAuthPassword>>,
    TError,
    { data: PutAuthPasswordBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putAuthPassword>>,
  TError,
  { data: PutAuthPasswordBody },
  TContext
> => {
  const mutationKey = ['putAuthPassword'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putAuthPassword>>,
    { data: PutAuthPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return putAuthPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutAuthPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof putAuthPassword>>
>;
export type PutAuthPasswordMutationBody = PutAuthPasswordBody;
export type PutAuthPasswordMutationError = void;

/**
 * @summary Update user password
 */
export const usePutAuthPassword = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAuthPassword>>,
    TError,
    { data: PutAuthPasswordBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putAuthPassword>>,
  TError,
  { data: PutAuthPasswordBody },
  TContext
> => {
  const mutationOptions = getPutAuthPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get all active sessions
 */
export const getAuthSessions = (signal?: AbortSignal) => {
  return customInstance<GetAuthSessions200Item[]>({ url: `/auth/sessions`, method: 'GET', signal });
};

export const getGetAuthSessionsQueryKey = () => {
  return [`/auth/sessions`] as const;
};

export const getGetAuthSessionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthSessions>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthSessions>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAuthSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthSessions>>> = ({ signal }) =>
    getAuthSessions(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuthSessions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetAuthSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAuthSessions>>>;
export type GetAuthSessionsQueryError = unknown;

export function useGetAuthSessions<
  TData = Awaited<ReturnType<typeof getAuthSessions>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthSessions>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getAuthSessions>>,
        TError,
        Awaited<ReturnType<typeof getAuthSessions>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetAuthSessions<
  TData = Awaited<ReturnType<typeof getAuthSessions>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthSessions>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getAuthSessions>>,
        TError,
        Awaited<ReturnType<typeof getAuthSessions>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetAuthSessions<
  TData = Awaited<ReturnType<typeof getAuthSessions>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthSessions>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get all active sessions
 */

export function useGetAuthSessions<
  TData = Awaited<ReturnType<typeof getAuthSessions>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthSessions>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetAuthSessionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Revoke all sessions except current
 */
export const deleteAuthSessions = () => {
  return customInstance<void>({ url: `/auth/sessions`, method: 'DELETE' });
};

export const getDeleteAuthSessionsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAuthSessions>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteAuthSessions>>, TError, void, TContext> => {
  const mutationKey = ['deleteAuthSessions'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAuthSessions>>, void> = () => {
    return deleteAuthSessions();
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAuthSessionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAuthSessions>>
>;

export type DeleteAuthSessionsMutationError = unknown;

/**
 * @summary Revoke all sessions except current
 */
export const useDeleteAuthSessions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAuthSessions>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof deleteAuthSessions>>, TError, void, TContext> => {
  const mutationOptions = getDeleteAuthSessionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Revoke a specific session
 */
export const deleteAuthSessionsSessionId = (sessionId: string) => {
  return customInstance<void>({ url: `/auth/sessions/${sessionId}`, method: 'DELETE' });
};

export const getDeleteAuthSessionsSessionIdMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAuthSessionsSessionId>>,
    TError,
    { sessionId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAuthSessionsSessionId>>,
  TError,
  { sessionId: string },
  TContext
> => {
  const mutationKey = ['deleteAuthSessionsSessionId'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAuthSessionsSessionId>>,
    { sessionId: string }
  > = (props) => {
    const { sessionId } = props ?? {};

    return deleteAuthSessionsSessionId(sessionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAuthSessionsSessionIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAuthSessionsSessionId>>
>;

export type DeleteAuthSessionsSessionIdMutationError = void;

/**
 * @summary Revoke a specific session
 */
export const useDeleteAuthSessionsSessionId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAuthSessionsSessionId>>,
    TError,
    { sessionId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAuthSessionsSessionId>>,
  TError,
  { sessionId: string },
  TContext
> => {
  const mutationOptions = getDeleteAuthSessionsSessionIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete user account
 */
export const deleteAuthAccount = () => {
  return customInstance<void>({ url: `/auth/account`, method: 'DELETE' });
};

export const getDeleteAuthAccountMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAuthAccount>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteAuthAccount>>, TError, void, TContext> => {
  const mutationKey = ['deleteAuthAccount'];
  const { mutation: mutationOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAuthAccount>>, void> = () => {
    return deleteAuthAccount();
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAuthAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAuthAccount>>
>;

export type DeleteAuthAccountMutationError = void;

/**
 * @summary Delete user account
 */
export const useDeleteAuthAccount = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAuthAccount>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof deleteAuthAccount>>, TError, void, TContext> => {
  const mutationOptions = getDeleteAuthAccountMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get list of available countries
 */
export const getSettingsCountries = (signal?: AbortSignal) => {
  return customInstance<GetSettingsCountries200Item[]>({
    url: `/settings/countries`,
    method: 'GET',
    signal,
  });
};

export const getGetSettingsCountriesQueryKey = () => {
  return [`/settings/countries`] as const;
};

export const getGetSettingsCountriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettingsCountries>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsCountries>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsCountriesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsCountries>>> = ({ signal }) =>
    getSettingsCountries(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSettingsCountries>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetSettingsCountriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSettingsCountries>>
>;
export type GetSettingsCountriesQueryError = unknown;

export function useGetSettingsCountries<
  TData = Awaited<ReturnType<typeof getSettingsCountries>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsCountries>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsCountries>>,
        TError,
        Awaited<ReturnType<typeof getSettingsCountries>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsCountries<
  TData = Awaited<ReturnType<typeof getSettingsCountries>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsCountries>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsCountries>>,
        TError,
        Awaited<ReturnType<typeof getSettingsCountries>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsCountries<
  TData = Awaited<ReturnType<typeof getSettingsCountries>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsCountries>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get list of available countries
 */

export function useGetSettingsCountries<
  TData = Awaited<ReturnType<typeof getSettingsCountries>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsCountries>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetSettingsCountriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get list of available tax filing statuses
 */
export const getSettingsFilingStatuses = (signal?: AbortSignal) => {
  return customInstance<GetSettingsFilingStatuses200Item[]>({
    url: `/settings/filing-statuses`,
    method: 'GET',
    signal,
  });
};

export const getGetSettingsFilingStatusesQueryKey = () => {
  return [`/settings/filing-statuses`] as const;
};

export const getGetSettingsFilingStatusesQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettingsFilingStatuses>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsFilingStatuses>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsFilingStatusesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsFilingStatuses>>> = ({
    signal,
  }) => getSettingsFilingStatuses(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSettingsFilingStatuses>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetSettingsFilingStatusesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSettingsFilingStatuses>>
>;
export type GetSettingsFilingStatusesQueryError = unknown;

export function useGetSettingsFilingStatuses<
  TData = Awaited<ReturnType<typeof getSettingsFilingStatuses>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsFilingStatuses>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsFilingStatuses>>,
        TError,
        Awaited<ReturnType<typeof getSettingsFilingStatuses>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsFilingStatuses<
  TData = Awaited<ReturnType<typeof getSettingsFilingStatuses>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsFilingStatuses>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSettingsFilingStatuses>>,
        TError,
        Awaited<ReturnType<typeof getSettingsFilingStatuses>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetSettingsFilingStatuses<
  TData = Awaited<ReturnType<typeof getSettingsFilingStatuses>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsFilingStatuses>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get list of available tax filing statuses
 */

export function useGetSettingsFilingStatuses<
  TData = Awaited<ReturnType<typeof getSettingsFilingStatuses>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSettingsFilingStatuses>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetSettingsFilingStatusesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get price history for a specific asset
 */
export const getMarketAssetsAssetIdPriceHistory = (assetId: string, signal?: AbortSignal) => {
  return customInstance<GetMarketAssetsAssetIdPriceHistory200Item[]>({
    url: `/market/assets/${assetId}/price-history`,
    method: 'GET',
    signal,
  });
};

export const getGetMarketAssetsAssetIdPriceHistoryQueryKey = (assetId: string) => {
  return [`/market/assets/${assetId}/price-history`] as const;
};

export const getGetMarketAssetsAssetIdPriceHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>,
  TError = unknown,
>(
  assetId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>, TError, TData>
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMarketAssetsAssetIdPriceHistoryQueryKey(assetId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>> = ({
    signal,
  }) => getMarketAssetsAssetIdPriceHistory(assetId, signal);

  return { queryKey, queryFn, enabled: !!assetId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetMarketAssetsAssetIdPriceHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>
>;
export type GetMarketAssetsAssetIdPriceHistoryQueryError = unknown;

export function useGetMarketAssetsAssetIdPriceHistory<
  TData = Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>,
  TError = unknown,
>(
  assetId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>,
          TError,
          Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>
        >,
        'initialData'
      >;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetMarketAssetsAssetIdPriceHistory<
  TData = Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>,
  TError = unknown,
>(
  assetId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>,
          TError,
          Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>
        >,
        'initialData'
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetMarketAssetsAssetIdPriceHistory<
  TData = Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>,
  TError = unknown,
>(
  assetId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get price history for a specific asset
 */

export function useGetMarketAssetsAssetIdPriceHistory<
  TData = Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>,
  TError = unknown,
>(
  assetId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMarketAssetsAssetIdPriceHistory>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = getGetMarketAssetsAssetIdPriceHistoryQueryOptions(assetId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
