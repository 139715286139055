import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

interface TaxYearSelectorProps {
  form: UseFormReturn<any>;
  currentYear: number;
}

export const TaxYearSelector = ({ form, currentYear }: TaxYearSelectorProps) => {
  const [availableYears, setAvailableYears] = useState<string[]>([]);

  useEffect(() => {
    // Generate years array (current year + 1, current year, and 8 years before)
    const years = Array.from({ length: 10 }, (_, i) => {
      if (i === 0) return (currentYear + 1).toString();
      if (i === 1) return currentYear.toString();
      return (currentYear - (i - 1)).toString();
    });
    setAvailableYears(years);
  }, [currentYear]);

  const handleYearChange = (year: string) => {
    form.setValue('taxYear', year);
  };

  return (
    <FormField
      control={form.control}
      name="taxYear"
      render={({ field }) => {
        const isPastYear = field.value && parseInt(field.value) < currentYear - 1;

        return (
          <FormItem className="space-y-2">
            <FormLabel className="text-sm font-medium text-foreground">Tax Year</FormLabel>
            <br />
            <div className="inline-flex rounded-md border border-border shadow-sm">
              <Button
                type="button"
                variant={field.value === (currentYear - 1).toString() ? 'default' : 'ghost'}
                className="rounded-none rounded-l-md px-4 hover:bg-accent"
                onClick={() => handleYearChange((currentYear - 1).toString())}
              >
                {currentYear - 1}
              </Button>
              <Button
                type="button"
                variant={field.value === currentYear.toString() ? 'default' : 'ghost'}
                className="rounded-none border-x border-border px-4 hover:bg-accent"
                onClick={() => handleYearChange(currentYear.toString())}
              >
                {currentYear}
              </Button>
              <Button
                type="button"
                variant={field.value === (currentYear + 1).toString() ? 'default' : 'ghost'}
                className="rounded-none border-x border-border px-4 hover:bg-accent"
                onClick={() => handleYearChange((currentYear + 1).toString())}
              >
                {currentYear + 1}
              </Button>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger
                    className={`w-[120px] rounded-none rounded-r-md border-0 border-l border-border ${
                      isPastYear 
                        ? 'bg-primary text-primary-foreground hover:bg-primary/90' 
                        : 'bg-background hover:bg-accent'
                    }`}
                  >
                    <SelectValue placeholder="Other years" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {availableYears
                    .filter((year) => {
                      const yearNum = parseInt(year);
                      return yearNum < currentYear - 1;
                    })
                    .map((year) => (
                      <SelectItem key={year} value={year} className="hover:bg-accent">
                        {year}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            <FormMessage className="text-sm text-destructive" />
          </FormItem>
        );
      }}
    />
  );
};
