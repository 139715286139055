import { authService, settingsService } from '@/api/services';
import type { PersonalInfo } from '@/api/types';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { useLanguage } from '@/i18n/LanguageContext';
import { cn } from '@/lib/utils';
import {
  ChevronDown,
  CreditCard,
  FileBox,
  LayoutDashboard,
  LineChart,
  LogOut,
  Menu,
  Receipt,
  Settings,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface NavItemProps {
  icon: React.ReactNode;
  label: string;
  href: string;
  active: boolean;
  onClick?: () => void;
}

const NavItem = ({ icon, label, href, active, onClick }: NavItemProps) => {
  return (
    <Link to={href} onClick={onClick}>
      <Button
        variant="ghost"
        className={cn(
          'mb-1 w-full justify-start gap-2',
          active && 'bg-secondary font-medium text-primary'
        )}
      >
        {icon}
        {label}
      </Button>
    </Link>
  );
};

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sheetOpen, setSheetOpen] = useState(false);
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);
  const { t } = useLanguage();
  const [userInfo, setUserInfo] = useState<PersonalInfo | null>(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const info = await settingsService.getPersonalInfo();
        setUserInfo(info);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };
    fetchUserInfo();
  }, []);

  const navItems = [
    { icon: <LayoutDashboard size={20} />, label: t('nav.dashboard'), href: '/' },
    { icon: <CreditCard size={20} />, label: t('nav.accounts'), href: '/accounts' },
    { icon: <Receipt size={20} />, label: t('nav.transactions'), href: '/transactions' },
    { icon: <FileBox size={20} />, label: t('nav.vault'), href: '/vault' },
    { icon: <LineChart size={20} />, label: t('nav.market'), href: '/market' },
  ];

  const handleLogout = () => {
    authService.removeToken();
    navigate('/login');
    setSheetOpen(false);
  };

  const handleNavigation = () => {
    setSheetOpen(false);
  };

  const UserProfile = ({ isMobile = false }: { isMobile?: boolean }) => (
    <div className={cn('border-t pt-6', isMobile ? 'mt-6' : 'mt-auto')}>
      <div className="space-y-2">
        <div
          className="flex cursor-pointer items-center gap-2 rounded-md px-2 py-2 transition-colors hover:bg-secondary/50"
          onClick={() => setIsProfileExpanded(!isProfileExpanded)}
        >
          <Avatar className="h-8 w-8">
            <AvatarImage src="/avatars/01.png" alt={userInfo?.firstName || 'User'} />
            <AvatarFallback>{userInfo?.firstName?.[0] || 'U'}</AvatarFallback>
          </Avatar>
          <div className="flex flex-1 flex-col items-start">
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-medium">
                {userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : t('common.loading')}
              </span>
              <ChevronDown
                size={16}
                className={cn(
                  'transition-transform duration-200',
                  isProfileExpanded && 'rotate-180 transform'
                )}
              />
            </div>
            <span className="text-xs text-muted-foreground">{userInfo?.email || ''}</span>
          </div>
        </div>
        <div
          className={cn(
            'overflow-hidden transition-all duration-200',
            isProfileExpanded ? 'max-h-[200px]' : 'max-h-0'
          )}
        >
          <div className="space-y-1">
            <NavItem
              icon={<Settings size={20} />}
              label={t('nav.settings')}
              href="/settings"
              active={location.pathname === '/settings'}
              onClick={handleNavigation}
            />
            <Button
              variant="ghost"
              className="w-full justify-start gap-2 text-destructive"
              onClick={handleLogout}
            >
              <LogOut size={20} />
              {t('nav.logout')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex h-screen flex-col">
      {/* Mobile Header */}
      <header className="flex h-14 items-center justify-between border-b px-4 md:hidden">
        <div className="text-lg font-bold text-primary">{t('app.name')}</div>
        <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
          <SheetTrigger asChild>
            <Button variant="ghost" size="icon">
              <Menu size={24} />
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="p-0">
            <div className="p-6">
              <div className="mb-6 flex items-center justify-between">
                <div className="text-lg font-bold text-primary">{t('app.name')}</div>
              </div>
              <nav className="space-y-1">
                {navItems.map((item) => (
                  <NavItem
                    key={item.href}
                    icon={item.icon}
                    label={item.label}
                    href={item.href}
                    active={location.pathname === item.href}
                    onClick={handleNavigation}
                  />
                ))}
              </nav>
              <UserProfile isMobile />
            </div>
          </SheetContent>
        </Sheet>
      </header>

      <div className="flex flex-1 overflow-hidden">
        {/* Desktop Sidebar */}
        <aside className="hidden w-64 border-r p-6 md:flex md:flex-col">
          <div className="mb-8 text-xl font-bold text-primary">{t('app.name')}</div>
          <nav className="space-y-1">
            {navItems.map((item) => (
              <NavItem
                key={item.href}
                icon={item.icon}
                label={item.label}
                href={item.href}
                active={location.pathname === item.href}
              />
            ))}
          </nav>
          <UserProfile />
        </aside>

        {/* Main Content */}
        <main className="flex-1 overflow-auto p-6">{children}</main>
      </div>
    </div>
  );
};

export default MainLayout;
