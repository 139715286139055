import { accountsService } from '@/api/services';
import { Account, NetWorthHistory } from '@/api/types';
import { AccountDetails } from '@/components/accounts/AccountDetails';
import { AccountForm } from '@/components/accounts/AccountForm';
import { NetWorthTrendCard } from '@/components/dashboard/NetWorthTrendCard';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { ConfirmationDialog } from '@/components/ui/confirmation-dialog';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useLanguage } from '@/i18n/LanguageContext';
import {
  Building,
  Building2,
  Coins,
  CreditCard,
  Home,
  MoreHorizontal,
  PiggyBank,
  Plus,
  Search,
  Wallet,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { Loading } from '@/components/ui/loading';

// Helper functions
const getAccountTypeColor = (type: string): string => {
  const colors: Record<string, string> = {
    bank: '#1E5591',
    investment: '#2E7D32',
    'real-estate': '#ED6C02',
    crypto: '#9C27B0',
    alternative: '#1976D2',
    liability: '#D32F2F',
  };
  return colors[type] || '#757575';
};

const calculateNetWorthHistory = (accounts: Account[]): NetWorthHistory[] => {
  const currentNetWorth = accounts.reduce((acc, account) => {
    return acc + (account.type === 'liability' ? -account.balance : account.balance);
  }, 0);

  return [
    { name: 'Jan', value: currentNetWorth * 0.95 },
    { name: 'Feb', value: currentNetWorth * 0.97 },
    { name: 'Mar', value: currentNetWorth * 0.99 },
    { name: 'Apr', value: currentNetWorth * 1.02 },
    { name: 'May', value: currentNetWorth * 1.05 },
    { name: 'Jun', value: currentNetWorth },
  ];
};

const Accounts = () => {
  const { t } = useLanguage();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('All Types');
  const [selectedInstitution, setSelectedInstitution] = useState('All Institutions');
  const [activeTab, setActiveTab] = useState('accounts');
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [netWorthHistory, setNetWorthHistory] = useState<NetWorthHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const loadAccounts = async () => {
    try {
      const accountsData = await accountsService.getAccounts();
      setAccounts(accountsData);
      // Calculate net worth history
      const history = calculateNetWorthHistory(accountsData);
      setNetWorthHistory(history);
    } catch (error) {
      console.error('Failed to load accounts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAccounts();
  }, []);

  const handleAddAccount = async (data: any) => {
    try {
      await accountsService.createAccount(data);
      setIsAddDialogOpen(false);
      loadAccounts();
    } catch (error) {
      console.error('Failed to create account:', error);
    }
  };

  const handleEditAccount = async (data: any) => {
    if (!selectedAccount) return;
    try {
      await accountsService.updateAccount(selectedAccount.id, data);
      setIsEditDialogOpen(false);
      setSelectedAccount(null);
      loadAccounts();
    } catch (error) {
      console.error('Failed to update account:', error);
    }
  };

  const handleDeleteAccount = async () => {
    if (!selectedAccount) return;
    try {
      await accountsService.deleteAccount(selectedAccount.id);
      setIsDeleteDialogOpen(false);
      setSelectedAccount(null);
      loadAccounts();
    } catch (error) {
      console.error('Failed to delete account:', error);
    }
  };

  const handleRefreshBalance = async () => {
    if (!selectedAccount) return;
    try {
      const updatedAccount = await accountsService.refreshAccountBalance(selectedAccount.id);
      setSelectedAccount(updatedAccount);
      loadAccounts();
    } catch (error) {
      console.error('Failed to refresh balance:', error);
    }
  };

  const filteredAccounts = accounts.filter((account) => {
    // Filter by search term
    const matchesSearch = account.name.toLowerCase().includes(searchTerm.toLowerCase());

    // Filter by type
    const matchesType = selectedType === 'All Types' || account.type === selectedType;

    // Filter by institution
    const matchesInstitution =
      selectedInstitution === 'All Institutions' || account.institution === selectedInstitution;

    return matchesSearch && matchesType && matchesInstitution;
  });

  const getAccountIcon = (type: string) => {
    switch (type) {
      case 'bank':
        return <Building className="h-5 w-5" />;
      case 'investment':
        return <PiggyBank className="h-5 w-5" />;
      case 'real-estate':
        return <Home className="h-5 w-5" />;
      case 'crypto':
        return <Coins className="h-5 w-5" />;
      case 'alternative':
        return <Building2 className="h-5 w-5" />;
      case 'liability':
        return <CreditCard className="h-5 w-5" />;
      default:
        return <Wallet className="h-5 w-5" />;
    }
  };

  // Calculate totals for net worth tab
  const totalAssets = accounts
    .filter((acc) => acc.type !== 'liability')
    .reduce((acc, curr) => acc + curr.balance, 0);

  const totalLiabilities = accounts
    .filter((acc) => acc.type === 'liability')
    .reduce((acc, curr) => acc + curr.balance, 0);

  const netWorth = totalAssets - totalLiabilities;
  const previousNetWorth = netWorthHistory[netWorthHistory.length - 2]?.value || netWorth;
  const monthlyChange = netWorth - previousNetWorth;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">{t('accounts.title')}</h1>
        <Button onClick={() => setIsAddDialogOpen(true)}>
          <Plus className="mr-2 h-4 w-4" /> {t('accounts.actions.add')}
        </Button>
      </div>

      <Card>
        <Tabs defaultValue="accounts" onValueChange={setActiveTab}>
          <CardHeader className="pb-0">
            <div className="mb-2 flex items-center justify-between">
              <CardTitle>{t('accounts.financialAccounts.title')}</CardTitle>
              <TabsList>
                <TabsTrigger value="accounts">{t('accounts.tabs.accounts')}</TabsTrigger>
                <TabsTrigger value="net-worth">{t('accounts.tabs.netWorth')}</TabsTrigger>
              </TabsList>
            </div>
            <CardDescription>{t('accounts.financialAccounts.description')}</CardDescription>
          </CardHeader>

          <CardContent className="pt-6">
            <TabsContent value="accounts" className="mt-0">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                  <div className="relative w-full sm:w-80">
                    <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      type="search"
                      placeholder={t('accounts.search.placeholder')}
                      className="pl-8"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <div className="flex flex-1 flex-col gap-2 sm:flex-row">
                    <Select value={selectedType} onValueChange={setSelectedType}>
                      <SelectTrigger className="w-full sm:w-auto">
                        <SelectValue placeholder={t('accounts.filters.type.placeholder')} />
                      </SelectTrigger>
                      <SelectContent>
                        {[
                          'All Types',
                          'bank',
                          'investment',
                          'real-estate',
                          'crypto',
                          'alternative',
                          'liability',
                        ].map((type) => (
                          <SelectItem key={type} value={type}>
                            {type === 'All Types'
                              ? t('accounts.filters.type.all')
                              : t(`accounts.types.${type}`)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <Select value={selectedInstitution} onValueChange={setSelectedInstitution}>
                      <SelectTrigger className="w-full sm:w-auto">
                        <SelectValue placeholder={t('accounts.filters.institution.placeholder')} />
                      </SelectTrigger>
                      <SelectContent>
                        {[
                          'All Institutions',
                          'Chase',
                          'Ally Bank',
                          'Fidelity',
                          'Vanguard',
                          'Robinhood',
                          'Charles Schwab',
                          'Coinbase',
                          'Binance',
                          'KKR',
                          'Wells Fargo',
                          'Bank of America',
                        ].map((institution) => (
                          <SelectItem key={institution} value={institution}>
                            {institution}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="mb-2 text-sm text-muted-foreground">
                  {t('accounts.showing')} {filteredAccounts.length} {t('accounts.accounts')}
                </div>

                <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                  {filteredAccounts.map((account) => (
                    <Card key={account.id} className="overflow-hidden">
                      <div className="flex items-center p-4 pb-0">
                        <div className="mr-3 rounded bg-primary/10 p-2">
                          {getAccountIcon(account.type)}
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="truncate font-medium" title={account.name}>
                            {account.name}
                          </div>
                          <div className="text-xs text-muted-foreground">{account.institution}</div>
                        </div>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="icon" className="h-8 w-8">
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem
                              onClick={() => {
                                setSelectedAccount(account);
                                setIsDetailsDialogOpen(true);
                              }}
                            >
                              {t('accounts.actions.viewDetails')}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => {
                                setSelectedAccount(account);
                                setIsEditDialogOpen(true);
                              }}
                            >
                              {t('accounts.actions.edit')}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => {
                                setSelectedAccount(account);
                                handleRefreshBalance();
                              }}
                            >
                              {t('accounts.actions.refreshBalance')}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="text-red-500"
                              onClick={() => {
                                setSelectedAccount(account);
                                setIsDeleteDialogOpen(true);
                              }}
                            >
                              {t('accounts.actions.delete')}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                      <CardContent className="p-4 pt-3">
                        <div className="flex items-center justify-between">
                          <span className="text-2xl font-bold">
                            ${account.balance.toLocaleString()}
                          </span>
                          <span className="text-xs text-muted-foreground">
                            {t('accounts.lastUpdated')}{' '}
                            {new Date(account.lastUpdated).toLocaleDateString()}
                          </span>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>

                {filteredAccounts.length === 0 && (
                  <div className="py-12 text-center">
                    <Wallet className="mx-auto mb-4 h-12 w-12 text-muted-foreground opacity-50" />
                    <h3 className="mb-1 text-lg font-medium">{t('accounts.noAccounts')}</h3>
                    <p className="text-muted-foreground">{t('accounts.noAccountsDescription')}</p>
                    <Button className="mt-4" onClick={() => setIsAddDialogOpen(true)}>
                      <Plus className="mr-2 h-4 w-4" /> {t('accounts.actions.add')}
                    </Button>
                  </div>
                )}
              </div>
            </TabsContent>

            <TabsContent value="net-worth" className="mt-0">
              <div className="space-y-4">
                <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4">
                  <Card>
                    <CardContent className="p-6">
                      <div className="mb-1 text-sm font-medium text-muted-foreground">
                        {t('accounts.netWorth.totalAssets')}
                      </div>
                      <div className="text-2xl font-bold">${totalAssets.toLocaleString()}</div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="p-6">
                      <div className="mb-1 text-sm font-medium text-muted-foreground">
                        {t('accounts.netWorth.totalLiabilities')}
                      </div>
                      <div className="text-2xl font-bold text-red-500">
                        -${totalLiabilities.toLocaleString()}
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="p-6">
                      <div className="mb-1 text-sm font-medium text-muted-foreground">
                        {t('accounts.netWorth.netWorth')}
                      </div>
                      <div className="text-2xl font-bold text-green-500">
                        ${netWorth.toLocaleString()}
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="p-6">
                      <div className="mb-1 text-sm font-medium text-muted-foreground">
                        {t('accounts.netWorth.monthlyChange')}
                      </div>
                      <div
                        className={`text-2xl font-bold ${monthlyChange >= 0 ? 'text-green-500' : 'text-red-500'}`}
                      >
                        {monthlyChange >= 0 ? '+' : ''}${Math.abs(monthlyChange).toLocaleString()}
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <NetWorthTrendCard data={netWorthHistory} />
              </div>
            </TabsContent>
          </CardContent>
        </Tabs>
      </Card>

      {/* Add Account Dialog */}
      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('accounts.add.title')}</DialogTitle>
            <DialogDescription>{t('accounts.add.description')}</DialogDescription>
          </DialogHeader>
          <AccountForm onSubmit={handleAddAccount} onCancel={() => setIsAddDialogOpen(false)} />
        </DialogContent>
      </Dialog>

      {/* Edit Account Dialog */}
      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('accounts.edit.title')}</DialogTitle>
            <DialogDescription>{t('accounts.edit.description')}</DialogDescription>
          </DialogHeader>
          {selectedAccount && (
            <AccountForm
              initialData={selectedAccount}
              onSubmit={handleEditAccount}
              onCancel={() => {
                setIsEditDialogOpen(false);
                setSelectedAccount(null);
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Account Details Dialog */}
      <Dialog open={isDetailsDialogOpen} onOpenChange={setIsDetailsDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>{t('accounts.details.title')}</DialogTitle>
            <DialogDescription>{t('accounts.details.description')}</DialogDescription>
          </DialogHeader>
          {selectedAccount && (
            <AccountDetails
              account={selectedAccount}
              onEdit={() => {
                setIsDetailsDialogOpen(false);
                setIsEditDialogOpen(true);
              }}
              onDelete={() => {
                setIsDetailsDialogOpen(false);
                setIsDeleteDialogOpen(true);
              }}
              onRefreshBalance={handleRefreshBalance}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Account Confirmation Dialog */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
        onConfirm={handleDeleteAccount}
        title={t('accounts.delete.title')}
        description={t('accounts.delete.description')}
      />
    </div>
  );
};

export default Accounts;
