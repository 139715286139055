import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useLanguage } from '@/i18n/LanguageContext.tsx';
import { ArrowDown, ArrowUp } from 'lucide-react';

interface NetWorthCardProps {
  totalNetWorth: number;
  changePercentage: number;
  changeAmount: number;
  period: string;
}

export const NetWorthCard = ({
  totalNetWorth,
  changePercentage,
  changeAmount,
  period,
}: NetWorthCardProps) => {
  const { t } = useLanguage();
  const isPositiveChange = changePercentage >= 0;

  return (
    <Card className="shadow-sm">
      <CardHeader className="pb-2">
        <CardDescription>{t('dashboard.netWorth.title')}</CardDescription>
        <CardTitle className="text-3xl font-bold">${totalNetWorth.toLocaleString()}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex items-center">
          <div
            className={`flex items-center ${
              isPositiveChange ? 'text-wealth-green' : 'text-wealth-red'
            }`}
          >
            {isPositiveChange ? (
              <ArrowUp size={16} className="mr-1" />
            ) : (
              <ArrowDown size={16} className="mr-1" />
            )}
            <span className="font-medium">
              {isPositiveChange ? '+' : ''}
              {changePercentage.toFixed(2)}%
            </span>
          </div>
          <span className="mx-2 text-muted-foreground">•</span>
          <span className="text-muted-foreground">
            {isPositiveChange ? '+' : ''}${changeAmount.toLocaleString()} {period}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export default NetWorthCard;
