import { Transaction } from '@/api/types';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useLanguage } from '@/i18n/LanguageContext';

interface RecentTransactionsCardProps {
  transactions: Transaction[];
}

export const RecentTransactionsCard = ({ transactions }: RecentTransactionsCardProps) => {
  const { t } = useLanguage();

  return (
    <Card className="shadow-sm">
      <CardHeader>
        <CardTitle>{t('dashboard.recentTransactions.title')}</CardTitle>
        <CardDescription>{t('dashboard.recentTransactions.description')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {transactions.map((transaction) => (
            <div
              key={transaction.id}
              className="flex items-center justify-between border-b pb-3 last:border-0 last:pb-0"
            >
              <div>
                <div className="font-medium">{transaction.description}</div>
                <div className="text-sm text-muted-foreground">
                  {new Date(transaction.date).toLocaleDateString()} • {transaction.account}
                </div>
              </div>
              <div
                className={
                  transaction.amount > 0
                    ? 'font-medium text-wealth-green'
                    : 'font-medium text-wealth-red'
                }
              >
                {transaction.amount > 0 ? '+' : ''}${Math.abs(transaction.amount).toLocaleString()}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
