import { transactionsService } from '@/api/services';
import type { Transaction } from '@/api/types';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useLanguage } from '@/i18n/LanguageContext';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { CalendarIcon, Columns, Download, MoreHorizontal, Plus, Search } from 'lucide-react';
import { useEffect, useState } from 'react';

interface Column {
  id: string;
  label: string;
  visible: boolean;
}

const Transactions = () => {
  const { t } = useLanguage();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('All Accounts');
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date | undefined>(undefined);
  const [selectedDateTo, setSelectedDateTo] = useState<Date | undefined>(undefined);
  const [activeTab, setActiveTab] = useState('all');
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState<Column[]>([
    { id: 'date', label: t('transactions.table.date'), visible: true },
    { id: 'description', label: t('transactions.table.description'), visible: true },
    { id: 'category', label: t('transactions.table.category'), visible: true },
    { id: 'account', label: t('transactions.table.account'), visible: true },
    { id: 'amount', label: t('transactions.table.amount'), visible: true },
    { id: 'actions', label: t('transactions.table.actions'), visible: true },
  ]);

  useEffect(() => {
    const loadTransactions = async () => {
      try {
        const transactionsData = await transactionsService.getTransactions();
        setTransactions(transactionsData);
      } catch (error) {
        console.error('Failed to load transactions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadTransactions();
  }, []);

  const toggleColumn = (columnId: string) => {
    setColumns(
      columns.map((col) => (col.id === columnId ? { ...col, visible: !col.visible } : col))
    );
  };

  const filteredTransactions = transactions.filter((transaction) => {
    // Filter by search term
    const matchesSearch = transaction.description.toLowerCase().includes(searchTerm.toLowerCase());

    // Filter by account
    const matchesAccount =
      selectedAccount === 'All Accounts' || transaction.account === selectedAccount;

    // Filter by category
    const matchesCategory =
      selectedCategory === 'All Categories' || transaction.category === selectedCategory;

    // Filter by date range
    const transactionDate = new Date(transaction.date);
    const matchesDateFrom = !selectedDateFrom || transactionDate >= selectedDateFrom;
    const matchesDateTo = !selectedDateTo || transactionDate <= selectedDateTo;

    // Filter by type (tab)
    const matchesType = activeTab === 'all' || transaction.type === activeTab;

    return (
      matchesSearch &&
      matchesAccount &&
      matchesCategory &&
      matchesDateFrom &&
      matchesDateTo &&
      matchesType
    );
  });

  if (isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="text-center">
          <div className="mx-auto mb-4 h-12 w-12 animate-spin rounded-full border-b-2 border-primary"></div>
          <p className="text-muted-foreground">{t('common.loading')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">{t('transactions.title')}</h1>
        <div className="flex gap-2">
          <Button variant="outline">
            <Download className="mr-2 h-4 w-4" /> {t('transactions.actions.export')}
          </Button>
          <Button>
            <Plus className="mr-2 h-4 w-4" /> {t('transactions.actions.add')}
          </Button>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>{t('transactions.history.title')}</CardTitle>
          <CardDescription>{t('transactions.history.description')}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-4">
            <Tabs
              defaultValue="all"
              className="w-full"
              onValueChange={(value) => setActiveTab(value)}
            >
              <TabsList className="mb-4 grid grid-cols-4">
                <TabsTrigger value="all">{t('transactions.tabs.all')}</TabsTrigger>
                <TabsTrigger value="expense">{t('transactions.tabs.expenses')}</TabsTrigger>
                <TabsTrigger value="income">{t('transactions.tabs.income')}</TabsTrigger>
                <TabsTrigger value="transfer">{t('transactions.tabs.transfers')}</TabsTrigger>
              </TabsList>
            </Tabs>

            <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
              <div className="relative w-full sm:w-80">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder={t('transactions.search.placeholder')}
                  className="pl-8"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="flex flex-1 flex-col gap-2 sm:flex-row">
                <Select value={selectedAccount} onValueChange={setSelectedAccount}>
                  <SelectTrigger className="w-full sm:w-auto">
                    <SelectValue placeholder={t('transactions.filters.account.placeholder')} />
                  </SelectTrigger>
                  <SelectContent>
                    {[
                      'All Accounts',
                      'Chase Checking',
                      'Chase Savings',
                      'Chase Credit Card',
                      'Fidelity 401(k)',
                      'Robinhood',
                    ].map((account) => (
                      <SelectItem key={account} value={account}>
                        {account}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select value={selectedCategory} onValueChange={setSelectedCategory}>
                  <SelectTrigger className="w-full sm:w-auto">
                    <SelectValue placeholder={t('transactions.filters.category.placeholder')} />
                  </SelectTrigger>
                  <SelectContent>
                    {[
                      'All Categories',
                      'Income',
                      'Food',
                      'Transportation',
                      'Housing',
                      'Utilities',
                      'Shopping',
                      'Transfer',
                    ].map((category) => (
                      <SelectItem key={category} value={category}>
                        {category}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <div className="flex gap-2">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className={cn(
                          'w-full justify-start text-left font-normal sm:w-auto',
                          !selectedDateFrom && 'text-muted-foreground'
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {selectedDateFrom ? (
                          format(selectedDateFrom, 'PPP')
                        ) : (
                          <span>{t('transactions.filters.date.from')}</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={selectedDateFrom}
                        onSelect={setSelectedDateFrom}
                        initialFocus
                        className={cn('pointer-events-auto p-3')}
                      />
                    </PopoverContent>
                  </Popover>

                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className={cn(
                          'w-full justify-start text-left font-normal sm:w-auto',
                          !selectedDateTo && 'text-muted-foreground'
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {selectedDateTo ? (
                          format(selectedDateTo, 'PPP')
                        ) : (
                          <span>{t('transactions.filters.date.to')}</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={selectedDateTo}
                        onSelect={setSelectedDateTo}
                        initialFocus
                        className={cn('pointer-events-auto p-3')}
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm text-muted-foreground">
                {t('transactions.showing')} {filteredTransactions.length}{' '}
                {t('transactions.transactions')}
              </div>
              <div className="flex gap-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" size="sm">
                      <Columns className="mr-2 h-4 w-4" />
                      <span className="hidden sm:inline">{t('transactions.actions.columns')}</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-[200px]">
                    {columns.map((column) => (
                      <DropdownMenuCheckboxItem
                        key={column.id}
                        checked={column.visible}
                        onCheckedChange={() => toggleColumn(column.id)}
                      >
                        {column.label}
                      </DropdownMenuCheckboxItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
                <Button variant="outline" size="sm">
                  <Download className="mr-2 h-4 w-4" />
                  <span className="hidden sm:inline">{t('transactions.actions.export')}</span>
                </Button>
              </div>
            </div>

            <div className="rounded-md border">
              <Table>
                <TableHeader>
                  <TableRow>
                    {columns.map(
                      (column) =>
                        column.visible && <TableHead key={column.id}>{column.label}</TableHead>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredTransactions.length > 0 ? (
                    filteredTransactions.map((transaction) => (
                      <TableRow key={transaction.id}>
                        {columns.find((col) => col.id === 'date')?.visible && (
                          <TableCell>{new Date(transaction.date).toLocaleDateString()}</TableCell>
                        )}
                        {columns.find((col) => col.id === 'description')?.visible && (
                          <TableCell>{transaction.description}</TableCell>
                        )}
                        {columns.find((col) => col.id === 'category')?.visible && (
                          <TableCell>{transaction.category}</TableCell>
                        )}
                        {columns.find((col) => col.id === 'account')?.visible && (
                          <TableCell>{transaction.account}</TableCell>
                        )}
                        {columns.find((col) => col.id === 'amount')?.visible && (
                          <TableCell
                            className={`text-right font-medium ${
                              transaction.amount > 0
                                ? 'text-wealth-green'
                                : transaction.type === 'transfer'
                                  ? 'text-wealth-blue'
                                  : 'text-wealth-red'
                            }`}
                          >
                            {transaction.amount > 0 ? '+' : ''}$
                            {Math.abs(transaction.amount).toLocaleString()}
                          </TableCell>
                        )}
                        {columns.find((col) => col.id === 'actions')?.visible && (
                          <TableCell>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="h-8 w-8 p-0">
                                  <MoreHorizontal className="h-4 w-4" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent align="end">
                                <DropdownMenuItem>
                                  {t('transactions.actions.edit')}
                                </DropdownMenuItem>
                                <DropdownMenuItem>
                                  {t('transactions.actions.changeCategory')}
                                </DropdownMenuItem>
                                <DropdownMenuItem>
                                  {t('transactions.actions.split')}
                                </DropdownMenuItem>
                                <DropdownMenuItem>
                                  {t('transactions.actions.delete')}
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.filter((col) => col.visible).length}
                        className="h-24 text-center"
                      >
                        {t('transactions.noTransactions')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Transactions;
