import vaultData from '@/api/mock/vault.json';
import { Document, GetDocumentsFolders200Item } from '@/api/generated/model';
import { useGetDocuments, useGetDocumentsFolders } from '@/api/generated/fintecyWealthManagerAPI';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useLanguage } from '@/i18n/LanguageContext.tsx';
import {
  Download,
  Eye,
  FileText,
  FolderPlus,
  MoreHorizontal,
  Pencil,
  Plus,
  Search,
  Trash2,
  Upload,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loading } from '@/components/ui/loading';

const Vault = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const { folderId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('All Types');
  const [selectedOrganization, setSelectedOrganization] = useState('All Organizations');
  const [selectedYear, setSelectedYear] = useState('All Years');
  const [activeTab, setActiveTab] = useState('documents');

  const { data: documents = [], isLoading: isDocumentsLoading } = useGetDocuments();
  const { data: folders = [], isLoading: isFoldersLoading } = useGetDocumentsFolders();

  const isLoading = isDocumentsLoading || isFoldersLoading;

  // Update URL when tab changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentTab = searchParams.get('tab') || 'documents';
    setActiveTab(currentTab);
  }, [location.search]);

  const handleTabChange = (value: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', value);
    navigate({ search: searchParams.toString() });
  };

  const handleFolderClick = (folderId: string) => {
    navigate(`/vault/${folderId}`);
  };

  const filteredDocuments = documents.filter((document) => {
    // Filter by search term
    const matchesSearch = document.name?.toLowerCase().includes(searchTerm.toLowerCase());

    // Filter by type
    const matchesType = selectedType === 'All Types' || document.type === selectedType;

    // Filter by organization
    const matchesOrganization =
      selectedOrganization === 'All Organizations' ||
      document.organization === selectedOrganization;

    // Filter by year
    const matchesYear = selectedYear === 'All Years' || document.year === selectedYear;

    return matchesSearch && matchesType && matchesOrganization && matchesYear;
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">{t('vault.title')}</h1>
        <div className="flex gap-2">
          <Button variant="outline">
            <Upload className="mr-2 h-4 w-4" /> {t('vault.actions.upload')}
          </Button>
          <Button>
            <FolderPlus className="mr-2 h-4 w-4" /> {t('vault.actions.newFolder')}
          </Button>
        </div>
      </div>

      <Card>
        <Tabs defaultValue={activeTab} onValueChange={handleTabChange}>
          <CardHeader className="pb-0">
            <div className="mb-2 flex items-center justify-between">
              <CardTitle>{t('vault.secureStorage.title')}</CardTitle>
              <TabsList>
                <TabsTrigger value="documents">{t('vault.tabs.documents')}</TabsTrigger>
                <TabsTrigger value="folders">{t('vault.tabs.folders')}</TabsTrigger>
              </TabsList>
            </div>
            <CardDescription>{t('vault.secureStorage.description')}</CardDescription>
          </CardHeader>

          <CardContent className="pt-6">
            <TabsContent value="documents" className="mt-0">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                  <div className="relative w-full sm:w-80">
                    <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      type="search"
                      placeholder={t('vault.search.documents')}
                      className="pl-8"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <div className="flex flex-1 flex-col gap-2 sm:flex-row">
                    <Select value={selectedType} onValueChange={setSelectedType}>
                      <SelectTrigger className="w-full sm:w-auto">
                        <SelectValue placeholder={t('vault.filters.type.placeholder')} />
                      </SelectTrigger>
                      <SelectContent>
                        {vaultData.documentTypes.map((type) => (
                          <SelectItem key={type} value={type}>
                            {type === 'All Types'
                              ? t('vault.filters.type.all')
                              : t(`vault.types.${type}`)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <Select value={selectedOrganization} onValueChange={setSelectedOrganization}>
                      <SelectTrigger className="w-full sm:w-auto">
                        <SelectValue placeholder={t('vault.filters.organization.placeholder')} />
                      </SelectTrigger>
                      <SelectContent>
                        {vaultData.organizations.map((org) => (
                          <SelectItem key={org} value={org}>
                            {org}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <Select value={selectedYear} onValueChange={setSelectedYear}>
                      <SelectTrigger className="w-full sm:w-auto">
                        <SelectValue placeholder={t('vault.filters.year.placeholder')} />
                      </SelectTrigger>
                      <SelectContent>
                        {vaultData.years.map((year) => (
                          <SelectItem key={year} value={year}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="mb-2 text-sm text-muted-foreground">
                  {t('vault.showing')} {filteredDocuments.length} {t('vault.documents')}
                </div>

                <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                  {filteredDocuments.map((document) => (
                    <Card key={document.id} className="overflow-hidden">
                      <div className="flex items-center p-4 pb-0">
                        <div className="mr-3 rounded bg-primary/10 p-2">
                          <FileText className="h-5 w-5 text-primary" />
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="truncate font-medium" title={document.name}>
                            {document.name}
                          </div>
                          <div className="text-xs text-muted-foreground">
                            {document.organization} • {document.year}
                          </div>
                        </div>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="icon" className="h-8 w-8">
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem>
                              <Eye className="mr-2 h-4 w-4" /> {t('vault.actions.view')}
                            </DropdownMenuItem>
                            <DropdownMenuItem>
                              <Download className="mr-2 h-4 w-4" /> {t('vault.actions.download')}
                            </DropdownMenuItem>
                            <DropdownMenuItem>
                              <Pencil className="mr-2 h-4 w-4" /> {t('vault.actions.rename')}
                            </DropdownMenuItem>
                            <DropdownMenuItem className="text-red-500">
                              <Trash2 className="mr-2 h-4 w-4" /> {t('vault.actions.delete')}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                      <CardContent className="p-4 pt-3">
                        <div className="flex items-center justify-between text-xs">
                          <span className="text-muted-foreground">
                            {t('vault.document.added')}{' '}
                            {new Date(document.date || '').toLocaleDateString()}
                          </span>
                          <span className="text-muted-foreground">{document.size}</span>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>

                {filteredDocuments.length === 0 && (
                  <div className="py-12 text-center">
                    <FileText className="mx-auto mb-4 h-12 w-12 text-muted-foreground opacity-50" />
                    <h3 className="mb-1 text-lg font-medium">{t('vault.noDocuments')}</h3>
                    <p className="text-muted-foreground">{t('vault.tryAdjusting')}</p>
                    <Button className="mt-4">
                      <Upload className="mr-2 h-4 w-4" /> {t('vault.actions.uploadDocuments')}
                    </Button>
                  </div>
                )}
              </div>
            </TabsContent>

            <TabsContent value="folders" className="mt-0">
              <div className="mb-4 flex justify-between">
                <div className="relative w-full sm:w-80">
                  <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                  <Input type="search" placeholder={t('vault.search.folders')} className="pl-8" />
                </div>
                <Button className="ml-2">
                  <Plus className="mr-2 h-4 w-4" /> {t('vault.actions.newFolder')}
                </Button>
              </div>

              <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {folders.map((folder) => (
                  <Card
                    key={folder.id}
                    className="cursor-pointer overflow-hidden transition-colors hover:bg-secondary/50"
                    onClick={() => handleFolderClick(folder.id?.toString() || '')}
                  >
                    <CardContent className="flex flex-col items-center p-6 text-center">
                      <div className="mb-4 rounded-full bg-primary/10 p-4">
                        <FileText className="h-8 w-8 text-primary" />
                      </div>
                      <h3 className="mb-1 font-medium">{folder.name}</h3>
                      <p className="text-sm text-muted-foreground">
                        {documents.filter(doc => doc.id?.startsWith(folder.id?.toString() || '')).length} {t('vault.document.documents')}
                      </p>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </TabsContent>
          </CardContent>
        </Tabs>
      </Card>
    </div>
  );
};

export default Vault;
