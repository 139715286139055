import { preferencesService } from '@/api/services';
import type { Preferences } from '@/api/types';
import { PersonalInfo } from '@/components/settings/PersonalInfo';
import { PreferencesTab } from '@/components/settings/Preferences';
import { Security } from '@/components/settings/Security';
import { Tax } from '@/components/settings/Tax';
import { Loading } from '@/components/ui/loading';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { toast } from '@/hooks/use-toast';
import { type Locale } from '@/i18n/i18n';
import { useLanguage } from '@/i18n/LanguageContext';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { theme: systemTheme, setTheme } = useTheme();
  const [preferences, setPreferences] = useState<Preferences | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t, locale, setLocale } = useLanguage();
  const [currentTheme, setCurrentTheme] = useState<string>('system');

  // Get active tab from URL or default to 'personal'
  const activeTab = location.hash.slice(1) || 'personal';

  const handleTabChange = (value: string) => {
    navigate(`#${value}`);
  };

  const handleLanguageChange = async (newLanguage: string) => {
    try {
      setLocale(newLanguage as Locale);
      if (preferences) {
        await updatePreferences({
          ...preferences,
          language: newLanguage as Locale,
        });
      }
    } catch (error) {
      console.error('Failed to update language preference:', error);
    }
  };

  useEffect(() => {
    const loadPreferences = async () => {
      try {
        setIsLoading(true);
        // First try to get from localStorage
        const storedPreferences = preferencesService.getPreferencesFromStorage();
        if (storedPreferences) {
          setPreferences(storedPreferences);
          setCurrentTheme(storedPreferences.theme || systemTheme);
          return;
        }

        // If not in localStorage, try API call
        const fetchedPreferences = await preferencesService.fetchPreferences();
        if (fetchedPreferences) {
          setPreferences(fetchedPreferences);
          setCurrentTheme(fetchedPreferences.theme || systemTheme);
          // Save to localStorage for future use
          preferencesService.savePreferencesToStorage(fetchedPreferences);
        }
      } catch (error) {
        console.error('Failed to load preferences:', error);
        setCurrentTheme(systemTheme);
      } finally {
        setIsLoading(false);
      }
    };
    loadPreferences();
  }, [systemTheme]);

  if (isLoading) {
    return <Loading />;
  }

  const updatePreferences = async (newPreferences: Preferences) => {
    try {
      setIsSaving(true);
      const updatedPreferences = await preferencesService.updatePreferences(newPreferences);
      setPreferences(updatedPreferences);
      if (newPreferences.theme) {
        setCurrentTheme(newPreferences.theme);
        setTheme(newPreferences.theme);
      }
      toast({
        title: t('common.save'),
        description: t('settings.preferences.description'),
      });
    } catch (error) {
      toast({
        title: t('errors.title'),
        description: t('errors.message'),
        variant: 'destructive',
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">{t('settings.title')}</h1>

      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <TabsList className="grid w-full grid-cols-4">
          <TabsTrigger value="personal">{t('settings.tabs.personal')}</TabsTrigger>
          <TabsTrigger value="tax">{t('settings.tabs.tax')}</TabsTrigger>
          <TabsTrigger value="preferences">{t('settings.tabs.preferences')}</TabsTrigger>
          <TabsTrigger value="security">{t('settings.tabs.security')}</TabsTrigger>
        </TabsList>

        <TabsContent value="personal" className="mt-6">
          <PersonalInfo />
        </TabsContent>

        <TabsContent value="tax" className="mt-6">
          <Tax />
        </TabsContent>

        <TabsContent value="preferences" className="mt-6">
          <PreferencesTab preferences={preferences} onUpdatePreferences={updatePreferences} />
        </TabsContent>

        <TabsContent value="security" className="mt-6">
          <Security />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Settings;
