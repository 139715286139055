import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useLanguage } from '@/i18n/LanguageContext';

interface AssetsAndLiabilitiesCardProps {
  totalAssets: number;
  totalLiabilities: number;
}

export const AssetsAndLiabilitiesCard = ({
  totalAssets: rawTotalAssets,
  totalLiabilities: rawTotalLiabilities,
}: AssetsAndLiabilitiesCardProps) => {
  const totalAssets = Math.abs(rawTotalAssets);
  const totalLiabilities = Math.abs(rawTotalLiabilities);
  const { t } = useLanguage();
  const assetRatio = (totalAssets / (totalAssets + totalLiabilities)) * 100;
  const debtRatio = 100 - assetRatio;

  return (
    <Card className="shadow-sm">
      <CardHeader className="pb-2">
        <CardDescription>{t('dashboard.assetsAndLiabilities.title')}</CardDescription>
        <div className="flex justify-between">
          <CardTitle>
            {t('dashboard.assetsAndLiabilities.assets')}: ${totalAssets.toLocaleString()}
          </CardTitle>
          <CardTitle className="text-wealth-red">
            {t('dashboard.assetsAndLiabilities.liabilities')}: ${totalLiabilities.toLocaleString()}
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent>
        <div className="mb-2 h-4 w-full rounded-full bg-secondary">
          <div
            className="h-4 rounded-full bg-wealth-green"
            style={{ width: `${assetRatio}%` }}
          ></div>
        </div>
        <div className="flex justify-between text-sm">
          <span>
            {t('dashboard.assetsAndLiabilities.assetRatio')}: {assetRatio.toFixed(1)}%
          </span>
          <span>
            {t('dashboard.assetsAndLiabilities.debtRatio')}: {debtRatio.toFixed(1)}%
          </span>
        </div>
      </CardContent>
    </Card>
  );
};
