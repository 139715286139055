import { useGetSettingsTax, usePutSettingsTax, useGetSettingsCountries, useGetSettingsFilingStatuses } from '@/api/generated/fintecyWealthManagerAPI';
import { TaxSettings, TaxSettingsFilingStatus } from '@/api/generated/model';
import { TaxYearSelector } from '@/components/TaxYearSelector';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/hooks/use-toast';
import { useLanguage } from '@/i18n/LanguageContext.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Loading } from "@/components/ui/loading.tsx";

// Form schema for tax settings
const taxFormSchema = z.object({
  residenceCountry: z.string(),
  taxYear: z.string(),
  filingStatus: z.enum(['single', 'married_joint', 'married_separate', 'head_household', 'qualifying_widow'] as const),
  hasInvestmentIncome: z.boolean().default(false),
  hasRentalIncome: z.boolean().default(false),
  hasForeignIncome: z.boolean().default(false),
  hasTaxCredits: z.boolean().default(false),
  taxNotes: z.string().optional(),
});

type TaxFormValues = z.infer<typeof taxFormSchema>;

interface TaxProps {
  initialData?: TaxFormValues;
}

export const Tax = ({ initialData }: TaxProps) => {
  const { t } = useLanguage();
  const currentYear = new Date().getFullYear();

  const { data: taxSettings, isLoading: isLoadingTax } = useGetSettingsTax();
  const { data: countries, isLoading: isLoadingCountries } = useGetSettingsCountries();
  const { data: filingStatuses, isLoading: isLoadingFilingStatuses } = useGetSettingsFilingStatuses();
  const { mutate: updateTaxSettings, isPending } = usePutSettingsTax();

  const taxForm = useForm<TaxFormValues>({
    resolver: zodResolver(taxFormSchema),
    defaultValues: initialData || {
      residenceCountry: 'US',
      taxYear: currentYear.toString(),
      filingStatus: 'single',
      hasInvestmentIncome: true,
      hasRentalIncome: true,
      hasForeignIncome: false,
      hasTaxCredits: true,
      taxNotes: '',
    },
  });

  useEffect(() => {
    if (taxSettings) {
      taxForm.reset({
        ...taxSettings,
        filingStatus: taxSettings.filingStatus as TaxSettingsFilingStatus,
      });
    }
  }, [taxSettings, taxForm]);

  const onSubmit = (data: TaxFormValues) => {
    updateTaxSettings(
      { data },
      {
        onSuccess: () => {
          toast({
            title: t('settings.tax.success.title'),
            description: t('settings.tax.success.description'),
          });
        },
        onError: (error) => {
          toast({
            title: t('settings.tax.error.title'),
            description: t('settings.tax.error.description'),
            variant: 'destructive',
          });
        },
      }
    );
  };

  if (isLoadingTax || isLoadingCountries || isLoadingFilingStatuses) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('settings.tax.title')}</CardTitle>
        <CardDescription>{t('settings.tax.description')}</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...taxForm}>
          <form onSubmit={taxForm.handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
              <FormField
                control={taxForm.control}
                name="residenceCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('settings.tax.residenceCountry')}</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t('settings.personal.selectCountry')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {countries?.map((country) => (
                          <SelectItem key={country.code} value={country.code}>
                            {country.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <TaxYearSelector form={taxForm} currentYear={currentYear} />
            </div>

            <FormField
              control={taxForm.control}
              name="filingStatus"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('settings.tax.filingStatus')}</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select filing status" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {filingStatuses?.map((status) => (
                        <SelectItem key={status.value} value={status.value}>
                          {status.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Separator />

            <div className="space-y-4">
              <h3 className="text-lg font-medium">{t('settings.tax.incomeTypes.title')}</h3>

              <FormField
                control={taxForm.control}
                name="hasInvestmentIncome"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        {t('settings.tax.incomeTypes.investment.label')}
                      </FormLabel>
                      <FormDescription>
                        {t('settings.tax.incomeTypes.investment.description')}
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={taxForm.control}
                name="hasRentalIncome"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        {t('settings.tax.incomeTypes.rental.label')}
                      </FormLabel>
                      <FormDescription>
                        {t('settings.tax.incomeTypes.rental.description')}
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={taxForm.control}
                name="hasForeignIncome"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        {t('settings.tax.incomeTypes.foreign.label')}
                      </FormLabel>
                      <FormDescription>
                        {t('settings.tax.incomeTypes.foreign.description')}
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={taxForm.control}
                name="hasTaxCredits"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        {t('settings.tax.incomeTypes.credits.label')}
                      </FormLabel>
                      <FormDescription>
                        {t('settings.tax.incomeTypes.credits.description')}
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={taxForm.control}
              name="taxNotes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('settings.tax.notes.label')}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t('settings.tax.notes.placeholder')}
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" disabled={isPending}>
              {isPending ? t('common.saving') : t('common.save')}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
