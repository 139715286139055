import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useLanguage } from '@/i18n/LanguageContext.tsx';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

interface AccountData {
  name: string;
  value: number;
  color: string;
}

interface AccountsSummaryProps {
  accounts: AccountData[];
  total: number;
}

export const AccountsSummary = ({ accounts, total }: AccountsSummaryProps) => {
  const { t } = useLanguage();

  return (
    <Card className="shadow-sm">
      <CardHeader>
        <CardTitle>{t('dashboard.accountsSummary.title')}</CardTitle>
        <CardDescription>{t('dashboard.accountsSummary.description')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={accounts}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {accounts.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Legend />
              <Tooltip
                formatter={(value: number) => [
                  `$${value.toLocaleString()}`,
                  t('dashboard.accountsSummary.value'),
                ]}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="mt-4 space-y-2">
          {accounts.map((account) => (
            <div key={account.name} className="flex items-center justify-between">
              <div className="flex items-center">
                <div
                  className="mr-2 h-3 w-3 rounded-full"
                  style={{ backgroundColor: account.color }}
                />
                <span>{account.name}</span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="font-medium">${account.value.toLocaleString()}</span>
                <span className="text-xs text-muted-foreground">
                  {((account.value / total) * 100).toFixed(1)}%
                </span>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default AccountsSummary;
