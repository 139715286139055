import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import ru from './locales/ru.json';

export type Locale = 'en' | 'es' | 'de' | 'ru' | 'fr';

export const defaultLocale: Locale = 'en';

export const availableLocales: Locale[] = ['en', 'es', 'de', 'ru', 'fr'];

export const translations = {
  en,
  es,
  de,
  ru,
  fr,
};

export function getTranslation(locale: Locale) {
  return translations[locale] || translations[defaultLocale];
}

export function t(key: string, locale: Locale = defaultLocale) {
  const translation = getTranslation(locale);
  return key.split('.').reduce((obj, key) => obj?.[key], translation) || key;
}
