import type {
  Document,
  Folder,
  MarketAsset,
  PersonalInfo,
  Preferences,
  SecuritySettings,
  TaxSettings,
  Transaction
} from "./types.ts";

import type { Account } from "./generated/model/account";
import type { LoginRequest, LoginResponse } from "./generated/model";
import {
  postAuthLogin,
  putAuthPassword,
  getAuthSessions,
  deleteAuthSessionsSessionId,
  deleteAuthSessions,
  deleteAuthAccount
} from "./generated/fintecyWealthManagerAPI";

// Mock data imports
import dashboardData from "./mock/dashboard.json";
import marketData from "./mock/market.json";
import preferencesData from "./mock/preferences.json";
import priceHistoryData from "./mock/price-history.json";
import settingsData from "./mock/settings.json";
import vaultData from "./mock/vault.json";

// Generated API client imports
import {
  deleteAccountsId,
  getAccounts,
  postAccounts,
  postAccountsIdRefresh,
  putAccountsId
} from "./generated/fintecyWealthManagerAPI";

const PREFERENCES_STORAGE_KEY = 'app_preferences';
const TOKEN_KEY = 'auth_token';

type Session = {
  id: string;
  device: string;
  location: string;
  lastActive: string;
  isCurrent: boolean;
};

export const authService = {
  async login(credentials: LoginRequest): Promise<LoginResponse> {
    const response = await postAuthLogin(credentials);
    if (response.token) {
      this.setToken(response.token);
    }
    return response;
  },

  async updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    await putAuthPassword({ currentPassword, newPassword });
  },

  setToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  },

  getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  isAuthenticated(): boolean {
    return !!this.getToken();
  },

  async getSessions(): Promise<Session[]> {
    const sessions = await getAuthSessions();
    return sessions as Session[];
  },

  async revokeSession(sessionId: string): Promise<void> {
    await deleteAuthSessionsSessionId(sessionId);
  },

  async revokeAllSessions(): Promise<void> {
    await deleteAuthSessions();
  },

  async deleteAccount(): Promise<void> {
    await deleteAuthAccount();
    this.removeToken();
  },
};

// Simulated API delay
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Accounts Service
export const accountsService = {
  async getAccounts(): Promise<Account[]> {
    return await getAccounts();
  },

  async createAccount(account: Omit<Account, 'id'>): Promise<Account> {
    return await postAccounts(account);
  },

  async updateAccount(id: string, account: Partial<Omit<Account, 'id'>>): Promise<Account> {
    return await putAccountsId(id, account);
  },

  async deleteAccount(id: string): Promise<void> {
    await deleteAccountsId(id);
  },

  async refreshAccountBalance(id: string): Promise<Account> {
    return await postAccountsIdRefresh(id);
  },
};

// Transactions Service
export const transactionsService = {
  async getTransactions(): Promise<Transaction[]> {
    await delay(500);
    return dashboardData.recentTransactions as Transaction[];
  },
};

// Market Service
export const marketService = {
  async getMarketAssets(type?: 'stock' | 'crypto' | 'forex'): Promise<MarketAsset[]> {
    await delay(500);
    const assets = marketData.assets as MarketAsset[];
    if (!type) return assets;
    return assets.filter((asset) => asset.type === type);
  },
  async getPriceHistory(assetId: string): Promise<Array<{ name: string; price: number }>> {
    await delay(500);
    // In a real app, this would be filtered by assetId
    return priceHistoryData.priceHistory;
  },
};

// Settings Service
export const settingsService = {
  async getPersonalInfo(): Promise<PersonalInfo> {
    await delay(500);
    return settingsData.personalInfo as PersonalInfo;
  },

  async updatePersonalInfo(info: PersonalInfo): Promise<PersonalInfo> {
    await delay(500);
    return info;
  },

  async getTaxSettings(): Promise<TaxSettings> {
    await delay(500);
    return settingsData.taxSettings as TaxSettings;
  },

  async updateTaxSettings(settings: TaxSettings): Promise<TaxSettings> {
    await delay(500);
    return settings;
  },

  async getPreferences(): Promise<Preferences> {
    await delay(500);
    return settingsData.preferences as unknown as Preferences;
  },

  async updatePreferences(preferences: Preferences): Promise<Preferences> {
    await delay(500);
    return preferences;
  },

  async getSecuritySettings(): Promise<SecuritySettings> {
    await delay(500);
    return settingsData.securitySettings as SecuritySettings;
  },

  async updateSecuritySettings(settings: SecuritySettings): Promise<SecuritySettings> {
    await delay(500);
    return settings;
  },

  async getCountries(): Promise<Array<{ code: string; name: string }>> {
    await delay(500);
    return [
      { code: 'US', name: 'United States' },
      { code: 'CA', name: 'Canada' },
      { code: 'GB', name: 'United Kingdom' },
      { code: 'AU', name: 'Australia' },
      { code: 'DE', name: 'Germany' },
      { code: 'FR', name: 'France' },
      { code: 'JP', name: 'Japan' },
      { code: 'CN', name: 'China' },
    ];
  },

  async getFilingStatuses(): Promise<Array<{ value: string; label: string }>> {
    await delay(500);
    return [
      { value: 'single', label: 'Single' },
      { value: 'married_joint', label: 'Married Filing Jointly' },
      { value: 'married_separate', label: 'Married Filing Separately' },
      { value: 'head_household', label: 'Head of Household' },
      { value: 'qualifying_widow', label: 'Qualifying Widow(er)' },
    ];
  },
};

export const preferencesService = {
  async fetchPreferences(): Promise<Preferences> {
    // In a real app, this would be an API call
    return {
      ...preferencesData,
      theme: preferencesData.theme as 'light' | 'dark' | 'system',
      language: preferencesData.language || 'en',
    };
  },

  async updatePreferences(preferences: Preferences): Promise<Preferences> {
    // In a real app, this would be an API call
    // For now, we'll just simulate a successful update
    this.savePreferencesToStorage(preferences);
    return preferences;
  },

  savePreferencesToStorage(preferences: Preferences): void {
    localStorage.setItem(PREFERENCES_STORAGE_KEY, JSON.stringify(preferences));
  },

  getPreferencesFromStorage(): Preferences | null {
    const stored = localStorage.getItem(PREFERENCES_STORAGE_KEY);
    if (!stored) return null;

    const preferences = JSON.parse(stored);
    return {
      ...preferences,
      theme: preferences.theme as 'light' | 'dark' | 'system',
      language: preferences.language || 'en',
    };
  },

  clearPreferencesFromStorage(): void {
    localStorage.removeItem(PREFERENCES_STORAGE_KEY);
  },
};
