import { Account } from '@/api/types';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useLanguage } from '@/i18n/LanguageContext';
import { Building, Building2, Coins, CreditCard, Home, PiggyBank, Wallet } from 'lucide-react';

interface AccountDetailsProps {
  account: Account;
  onEdit: () => void;
  onDelete: () => void;
  onRefreshBalance: () => void;
}

const getAccountIcon = (type: string) => {
  switch (type) {
    case 'bank':
      return <Building className="h-5 w-5" />;
    case 'investment':
      return <PiggyBank className="h-5 w-5" />;
    case 'real-estate':
      return <Home className="h-5 w-5" />;
    case 'crypto':
      return <Coins className="h-5 w-5" />;
    case 'alternative':
      return <Building2 className="h-5 w-5" />;
    case 'liability':
      return <CreditCard className="h-5 w-5" />;
    default:
      return <Wallet className="h-5 w-5" />;
  }
};

export function AccountDetails({
  account,
  onEdit,
  onDelete,
  onRefreshBalance,
}: AccountDetailsProps) {
  const { t } = useLanguage();

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="rounded bg-primary/10 p-2">{getAccountIcon(account.type)}</div>
              <div>
                <CardTitle>{account.name}</CardTitle>
              </div>
            </div>
            <div className="flex space-x-2">
              <Button variant="outline" onClick={onRefreshBalance}>
                {t('accounts.actions.refreshBalance')}
              </Button>
              <Button variant="outline" onClick={onEdit}>
                {t('common.edit')}
              </Button>
              <Button variant="destructive" onClick={onDelete}>
                {t('common.delete')}
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4 md:grid-cols-2">
            <div>
              <div className="text-sm font-medium text-muted-foreground">
                {t('accounts.details.type')}
              </div>
              <div className="mt-1">{t(`accounts.types.${account.type}`)}</div>
            </div>
            <div>
              <div className="text-sm font-medium text-muted-foreground">
                {t('accounts.details.institution')}
              </div>
              <div className="mt-1">{account.institution}</div>
            </div>
            <div>
              <div className="text-sm font-medium text-muted-foreground">
                {t('accounts.details.balance')}
              </div>
              <div className="mt-1 text-2xl font-bold">${account.balance.toLocaleString()}</div>
            </div>
            <div>
              <div className="text-sm font-medium text-muted-foreground">
                {t('accounts.details.lastUpdated')}
              </div>
              <div className="mt-1">{new Date(account.lastUpdated).toLocaleDateString()}</div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
