import {
  usePutAuthPassword,
  useGetAuthSessions,
  useDeleteAuthSessionsSessionId,
  useDeleteAuthSessions,
  useDeleteAuthAccount,
} from '@/api/generated/fintecyWealthManagerAPI';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { useLanguage } from '@/i18n/LanguageContext.tsx';
import { Check, X } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

interface PasswordRequirements {
  minLength: boolean;
  hasNumber: boolean;
  hasLowercase: boolean;
  hasUppercase: boolean;
  hasSpecial: boolean;
}

const validatePassword = (password: string): string | null => {
  if (!password) return 'Password cannot be empty';
  if (password.length < 8) return 'Password must be at least 8 characters long';
  if (!/[0-9]/.test(password)) return 'Password must contain at least one number';
  if (!/[a-z]/.test(password)) return 'Password must contain at least one lowercase letter';
  if (!/[A-Z]/.test(password)) return 'Password must contain at least one uppercase letter';
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password))
    return 'Password must contain at least one special character';
  return null;
};

const checkPasswordRequirements = (password: string): PasswordRequirements => ({
  minLength: password.length >= 8,
  hasNumber: /[0-9]/.test(password),
  hasLowercase: /[a-z]/.test(password),
  hasUppercase: /[A-Z]/.test(password),
  hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password),
});

export const Security = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirements>({
    minLength: false,
    hasNumber: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSpecial: false,
  });
  const [errors, setErrors] = useState<{
    currentPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
  }>({});

  const { data: sessions, isLoading: isLoadingSessions, error: sessionsError } = useGetAuthSessions();
  const { mutate: updatePassword, isPending: isUpdatingPassword } = usePutAuthPassword({
    mutation: {
      onSuccess: () => {
        toast.success(t('settings.security.password.success'));
        // Clear form
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setPasswordRequirements({
          minLength: false,
          hasNumber: false,
          hasLowercase: false,
          hasUppercase: false,
          hasSpecial: false,
        });
      },
      onError: (error: unknown) => {
        const errorMessage = error instanceof Error ? error.message : t('settings.security.password.error');
        toast.error(errorMessage);
      },
    },
  });

  const { mutate: revokeSession } = useDeleteAuthSessionsSessionId({
    mutation: {
      onSuccess: () => {
        toast.success(t('settings.security.sessions.revoked'));
      },
      onError: () => {
        toast.error(t('settings.security.sessions.error'));
      },
    },
  });

  const { mutate: revokeAllSessions } = useDeleteAuthSessions({
    mutation: {
      onSuccess: () => {
        toast.success(t('settings.security.sessions.allRevoked'));
      },
      onError: () => {
        toast.error(t('settings.security.sessions.error'));
      },
    },
  });

  const { mutate: deleteAccount } = useDeleteAuthAccount({
    mutation: {
      onSuccess: () => {
        toast.success(t('settings.security.dangerZone.deleteAccount.success'));
        navigate('/login');
      },
      onError: () => {
        toast.error(t('settings.security.dangerZone.deleteAccount.error'));
      },
    },
  });

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setNewPassword(password);
    setPasswordRequirements(checkPasswordRequirements(password));
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setConfirmPassword(password);
    if (password !== newPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: t('settings.security.password.errors.mustMatch'),
      }));
    } else {
      setErrors((prev) => ({ ...prev, confirmPassword: undefined }));
    }
  };

  const isFormValid = () => {
    // Check if current password is provided
    if (!currentPassword) return false;

    // Check if new password meets all requirements
    const newPasswordError = validatePassword(newPassword);
    if (newPasswordError) return false;

    // Check if passwords match
    if (newPassword !== confirmPassword) return false;

    // Check if all password requirements are met
    return Object.values(passwordRequirements).every((requirement) => requirement === true);
  };

  const handlePasswordUpdate = () => {
    // Reset errors
    setErrors({});

    // Validate all fields
    const newErrors: typeof errors = {};

    if (!currentPassword) {
      newErrors.currentPassword = t('settings.security.password.errors.currentRequired');
    }

    const newPasswordError = validatePassword(newPassword);
    if (newPasswordError) {
      newErrors.newPassword = newPasswordError;
    }

    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = t('settings.security.password.errors.mustMatch');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    updatePassword({ data: { currentPassword, newPassword } });
  };

  const handleRevokeSession = (sessionId: string) => {
    revokeSession({ sessionId });
  };

  const handleRevokeAllSessions = () => {
    revokeAllSessions();
  };

  const handleDeleteAccount = () => {
    deleteAccount();
  };

  const RequirementItem = ({ met, text }: { met: boolean; text: string }) => (
    <div className="flex items-center gap-2 text-sm">
      {met ? <Check className="h-4 w-4 text-green-500" /> : <X className="h-4 w-4 text-gray-400" />}
      <span className={met ? 'text-green-500' : 'text-gray-500'}>{text}</span>
    </div>
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('settings.security.title')}</CardTitle>
        <CardDescription>{t('settings.security.description')}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <h3 className="text-lg font-medium">{t('settings.security.password.title')}</h3>

          <div className="grid gap-2">
            <Label htmlFor="current_password">{t('settings.security.password.current')}</Label>
            <Input
              id="current_password"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className={errors.currentPassword ? 'border-red-500' : ''}
            />
            {errors.currentPassword && (
              <p className="text-sm text-red-500">{errors.currentPassword}</p>
            )}
          </div>

          <div className="grid gap-2">
            <Label htmlFor="new_password">{t('settings.security.password.new')}</Label>
            <Input
              id="new_password"
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              className={errors.newPassword ? 'border-red-500' : ''}
            />
            {errors.newPassword && <p className="text-sm text-red-500">{errors.newPassword}</p>}
          </div>

          <div className="grid gap-2">
            <Label htmlFor="confirm_password">{t('settings.security.password.confirm')}</Label>
            <Input
              id="confirm_password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className={errors.confirmPassword ? 'border-red-500' : ''}
            />
            {errors.confirmPassword && (
              <p className="text-sm text-red-500">{errors.confirmPassword}</p>
            )}
          </div>

          <div className="mt-2 space-y-1">
            <RequirementItem
              met={passwordRequirements.minLength}
              text={t('settings.security.password.requirements.minLength')}
            />
            <RequirementItem
              met={passwordRequirements.hasNumber}
              text={t('settings.security.password.requirements.hasNumber')}
            />
            <RequirementItem
              met={passwordRequirements.hasLowercase}
              text={t('settings.security.password.requirements.hasLowercase')}
            />
            <RequirementItem
              met={passwordRequirements.hasUppercase}
              text={t('settings.security.password.requirements.hasUppercase')}
            />
            <RequirementItem
              met={passwordRequirements.hasSpecial}
              text={t('settings.security.password.requirements.hasSpecial')}
            />
          </div>
          <Button onClick={handlePasswordUpdate} disabled={isUpdatingPassword || !isFormValid()}>
            {isUpdatingPassword ? t('common.loading') : t('settings.security.password.update')}
          </Button>
        </div>

        <Separator />

        <div className="space-y-4">
          <h3 className="text-lg font-medium">{t('settings.security.twoFactor.title')}</h3>

          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <p>{t('settings.security.twoFactor.enabled')}</p>
              <p className="text-sm text-muted-foreground">
                {t('settings.security.twoFactor.description')}
              </p>
            </div>
            <Switch id="2fa" defaultChecked />
          </div>

          <Button variant="outline">{t('settings.security.twoFactor.manage')}</Button>
        </div>

        <Separator />

        <div className="space-y-4">
          <h3 className="text-lg font-medium">{t('settings.security.sessions.title')}</h3>

          {isLoadingSessions ? (
            <p className="text-muted-foreground">{t('common.loading')}</p>
          ) : sessionsError ? (
            <p className="text-destructive">{t('common.error')}</p>
          ) : sessions && sessions.length > 0 ? (
            <>
              {sessions.map((session) => (
                <div key={session.id} className="rounded-md border p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="font-medium">{session.device || t('settings.security.sessions.unknownDevice')}</p>
                      <p className="text-sm text-muted-foreground">
                        {t('settings.security.sessions.lastActivity')}: {session.lastActive || t('settings.security.sessions.unknownTime')} •{' '}
                        {session.location || t('settings.security.sessions.unknownLocation')}
                      </p>
                    </div>
                    {session.isCurrent ? (
                      <div className="rounded-full bg-green-500/10 px-2 py-1 text-xs text-green-600">
                        {t('common.active')}
                      </div>
                    ) : (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => session.id && handleRevokeSession(session.id)}
                      >
                        {t('common.revoke')}
                      </Button>
                    )}
                  </div>
                </div>
              ))}

              <Button variant="outline" onClick={handleRevokeAllSessions}>
                {t('settings.security.sessions.signOutAll')}
              </Button>
            </>
          ) : (
            <p>{t('settings.security.sessions.noSessions')}</p>
          )}
        </div>

        <Separator />

        <div className="space-y-4">
          <h3 className="text-lg font-medium text-destructive">
            {t('settings.security.dangerZone.title')}
          </h3>

          <div className="rounded-md border border-destructive/50 p-4">
            <div className="flex flex-col space-y-2">
              <p className="font-medium">{t('settings.security.dangerZone.deleteAccount.title')}</p>
              <p className="text-sm text-muted-foreground">
                {t('settings.security.dangerZone.deleteAccount.description')}
              </p>
              <div>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="destructive" className="mt-2">
                      {t('settings.security.dangerZone.deleteAccount.button')}
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        {t('settings.security.dangerZone.deleteAccount.confirmTitle')}
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        {t('settings.security.dangerZone.deleteAccount.confirmDescription')}
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={handleDeleteAccount}
                        className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                      >
                        {t('settings.security.dangerZone.deleteAccount.confirmButton')}
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
