import { marketService } from '@/api/services';
import type { MarketAsset } from '@/api/types';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Loading } from '@/components/ui/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useLanguage } from '@/i18n/LanguageContext.tsx';
import {
  ArrowDown,
  ArrowDownUp,
  ArrowUp,
  Bitcoin,
  Building,
  CreditCard,
  Search,
  Star,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const getAssetIcon = (type: string) => {
  switch (type) {
    case 'stock':
      return <ArrowDownUp className="h-4 w-4" />;
    case 'crypto':
      return <Bitcoin className="h-4 w-4" />;
    case 'forex':
      return <CreditCard className="h-4 w-4" />;
    case 'real-estate':
      return <Building className="h-4 w-4" />;
    default:
      return <ArrowDownUp className="h-4 w-4" />;
  }
};

const Market = () => {
  const { t } = useLanguage();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('all');
  const [selectedAsset, setSelectedAsset] = useState<MarketAsset | null>(null);
  const [marketData, setMarketData] = useState<{
    stocks: MarketAsset[];
    crypto: MarketAsset[];
    forex: MarketAsset[];
  }>({
    stocks: [],
    crypto: [],
    forex: [],
  });
  const [watchlist, setWatchlist] = useState<MarketAsset[]>([]);
  const [priceChartData, setPriceChartData] = useState<Array<{ name: string; price: number }>>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        setLoading(true);
        const [stocks, crypto, forex] = await Promise.all([
          marketService.getMarketAssets('stock'),
          marketService.getMarketAssets('crypto'),
          marketService.getMarketAssets('forex'),
        ]);

        setMarketData({
          stocks,
          crypto,
          forex,
        });

        if (stocks.length > 0) {
          setSelectedAsset(stocks[0]);
        }
      } catch (error) {
        console.error('Error fetching market data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMarketData();
  }, []);

  useEffect(() => {
    const fetchPriceChartData = async () => {
      if (selectedAsset) {
        try {
          const data = await marketService.getPriceHistory(selectedAsset.id);
          setPriceChartData(data);
        } catch (error) {
          console.error('Error fetching price history:', error);
        }
      }
    };

    fetchPriceChartData();
  }, [selectedAsset]);

  // Determine which data set to use based on active tab
  const getActiveData = () => {
    switch (activeTab) {
      case 'all':
        return [...marketData.stocks, ...marketData.crypto, ...marketData.forex];
      case 'stocks':
        return marketData.stocks;
      case 'crypto':
        return marketData.crypto;
      case 'forex':
        return marketData.forex;
      default:
        return marketData.stocks;
    }
  };

  const activeData = getActiveData();

  // Filter data based on search term
  const filteredData = activeData.filter((asset) => {
    return (
      asset.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      asset.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleAddToWatchlist = (asset: MarketAsset) => {
    if (!watchlist.find((item) => item.id === asset.id)) {
      setWatchlist([...watchlist, asset]);
    }
  };

  const handleRemoveFromWatchlist = (asset: MarketAsset) => {
    setWatchlist(watchlist.filter((item) => item.id !== asset.id));
  };

  const isInWatchlist = (asset: MarketAsset) => {
    return watchlist.some((item) => item.id === asset.id);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">{t('market.title')}</h1>

      <div className="grid gap-6 md:grid-cols-5">
        <Card className="md:col-span-2">
          <CardHeader>
            <CardTitle>{t('market.overview.title')}</CardTitle>
            <CardDescription>{t('market.overview.description')}</CardDescription>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="all" onValueChange={setActiveTab}>
              <TabsList className="mb-4 grid w-full grid-cols-4">
                <TabsTrigger value="all">{t('market.filters.type.all')}</TabsTrigger>
                <TabsTrigger value="stocks">{t('market.filters.type.stocks')}</TabsTrigger>
                <TabsTrigger value="crypto">{t('market.filters.type.crypto')}</TabsTrigger>
                <TabsTrigger value="forex">{t('market.filters.type.forex')}</TabsTrigger>
              </TabsList>

              <div className="relative mb-4">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder={t('market.search.placeholder')}
                  className="pl-8"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="overflow-hidden rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>{t('market.table.name')}</TableHead>
                      <TableHead>{t('market.table.price')}</TableHead>
                      <TableHead>{t('market.table.change')}</TableHead>
                      <TableHead className="text-right">{t('common.actions')}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredData.length > 0 ? (
                      filteredData.map((asset) => (
                        <TableRow
                          key={asset.id}
                          className={asset.id === selectedAsset?.id ? 'bg-secondary' : ''}
                          onClick={() => setSelectedAsset(asset)}
                        >
                          <TableCell>
                            <div className="flex items-center gap-2">
                              <div className="rounded bg-primary/10 p-1">
                                {getAssetIcon(asset.type)}
                              </div>
                              <div>
                                <div className="font-medium">{asset.id}</div>
                                <div className="max-w-[120px] truncate text-xs text-muted-foreground">
                                  {asset.name}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            $
                            {asset.price.toLocaleString(undefined, {
                              minimumFractionDigits: asset.price < 1 ? 4 : 2,
                              maximumFractionDigits: asset.price < 1 ? 4 : 2,
                            })}
                          </TableCell>
                          <TableCell>
                            <div
                              className={`flex items-center ${
                                asset.changePercent >= 0 ? 'text-wealth-green' : 'text-wealth-red'
                              }`}
                            >
                              {asset.changePercent >= 0 ? (
                                <ArrowUp className="mr-1 h-3 w-3" />
                              ) : (
                                <ArrowDown className="mr-1 h-3 w-3" />
                              )}
                              {asset.changePercent.toFixed(2)}%
                            </div>
                          </TableCell>
                          <TableCell className="text-right">
                            <Button
                              variant="ghost"
                              size="icon"
                              className={`h-8 w-8 ${isInWatchlist(asset) ? 'text-wealth-yellow' : ''}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (isInWatchlist(asset)) {
                                  handleRemoveFromWatchlist(asset);
                                } else {
                                  handleAddToWatchlist(asset);
                                }
                              }}
                            >
                              <Star
                                className={`h-4 w-4 ${isInWatchlist(asset) ? 'fill-current' : ''}`}
                              />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} className="h-24 text-center">
                          {t('common.noResults')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </Tabs>
          </CardContent>
        </Card>

        {selectedAsset && (
          <Card className="md:col-span-3">
            <CardHeader>
              <div className="flex items-start justify-between">
                <div>
                  <CardTitle className="flex items-center gap-2">
                    <div className="rounded bg-primary/10 p-1">
                      {getAssetIcon(selectedAsset.type)}
                    </div>
                    {selectedAsset.id} - {selectedAsset.name}
                  </CardTitle>
                  <CardDescription>
                    {t('market.table.price')}: $
                    {selectedAsset.price.toLocaleString(undefined, {
                      minimumFractionDigits: selectedAsset.price < 1 ? 4 : 2,
                      maximumFractionDigits: selectedAsset.price < 1 ? 4 : 2,
                    })}
                  </CardDescription>
                </div>
                <div
                  className={`flex items-center text-lg font-semibold ${
                    selectedAsset.changePercent >= 0 ? 'text-wealth-green' : 'text-wealth-red'
                  }`}
                >
                  {selectedAsset.changePercent >= 0 ? (
                    <ArrowUp className="mr-1 h-5 w-5" />
                  ) : (
                    <ArrowDown className="mr-1 h-5 w-5" />
                  )}
                  {selectedAsset.change.toFixed(2)} ({selectedAsset.changePercent.toFixed(2)}%)
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <Tabs defaultValue="1d">
                <TabsList className="mb-4">
                  <TabsTrigger value="1d">1D</TabsTrigger>
                  <TabsTrigger value="5d">5D</TabsTrigger>
                  <TabsTrigger value="1m">1M</TabsTrigger>
                  <TabsTrigger value="6m">6M</TabsTrigger>
                  <TabsTrigger value="1y">1Y</TabsTrigger>
                  <TabsTrigger value="5y">5Y</TabsTrigger>
                </TabsList>

                <div className="h-72">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={priceChartData}
                      margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="name" axisLine={false} tickLine={false} />
                      <YAxis
                        domain={['dataMin - 0.5', 'dataMax + 0.5']}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(value) => `$${value}`}
                      />
                      <Tooltip
                        formatter={(value: number) => [
                          `$${value.toFixed(2)}`,
                          t('market.table.price'),
                        ]}
                        labelFormatter={(label) => `Time: ${label}`}
                      />
                      <Line
                        type="monotone"
                        dataKey="price"
                        stroke={selectedAsset.changePercent >= 0 ? '#27AE60' : '#E74C3C'}
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 6 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                <div className="mt-6 grid grid-cols-2 gap-4 md:grid-cols-4">
                  <div>
                    <div className="text-sm text-muted-foreground">
                      {t('market.table.marketCap')}
                    </div>
                    <div className="font-medium">{selectedAsset.marketCap || 'N/A'}</div>
                  </div>
                  <div>
                    <div className="text-sm text-muted-foreground">{t('market.table.volume')}</div>
                    <div className="font-medium">{selectedAsset.volume}</div>
                  </div>
                  <div>
                    <div className="text-sm text-muted-foreground">52 Week High</div>
                    <div className="font-medium">${(selectedAsset.price * 1.3).toFixed(2)}</div>
                  </div>
                  <div>
                    <div className="text-sm text-muted-foreground">52 Week Low</div>
                    <div className="font-medium">${(selectedAsset.price * 0.7).toFixed(2)}</div>
                  </div>
                </div>

                <div className="mt-6 flex gap-3">
                  <Button>{t('market.actions.buy')}</Button>
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (isInWatchlist(selectedAsset)) {
                        handleRemoveFromWatchlist(selectedAsset);
                      } else {
                        handleAddToWatchlist(selectedAsset);
                      }
                    }}
                  >
                    {isInWatchlist(selectedAsset)
                      ? t('market.actions.removeFromWatchlist')
                      : t('market.actions.addToWatchlist')}
                  </Button>
                </div>
              </Tabs>
            </CardContent>
          </Card>
        )}
      </div>

      <Card>
        <CardHeader>
          <CardTitle>{t('market.actions.watchlist')}</CardTitle>
          <CardDescription>{t('market.overview.description')}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t('market.table.name')}</TableHead>
                  <TableHead>{t('market.table.price')}</TableHead>
                  <TableHead>{t('market.table.change')}</TableHead>
                  <TableHead>{t('market.table.marketCap')}</TableHead>
                  <TableHead>{t('market.table.volume')}</TableHead>
                  <TableHead className="text-right">{t('common.actions')}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {watchlist.map((asset) => (
                  <TableRow key={asset.id}>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <div className="rounded bg-primary/10 p-1">{getAssetIcon(asset.type)}</div>
                        <div className="font-medium">{asset.id}</div>
                      </div>
                    </TableCell>
                    <TableCell>
                      $
                      {asset.price.toLocaleString(undefined, {
                        minimumFractionDigits: asset.price < 1 ? 4 : 2,
                        maximumFractionDigits: asset.price < 1 ? 4 : 2,
                      })}
                    </TableCell>
                    <TableCell>
                      <div
                        className={`flex items-center ${
                          asset.changePercent >= 0 ? 'text-wealth-green' : 'text-wealth-red'
                        }`}
                      >
                        {asset.changePercent >= 0 ? (
                          <ArrowUp className="mr-1 h-3 w-3" />
                        ) : (
                          <ArrowDown className="mr-1 h-3 w-3" />
                        )}
                        {asset.changePercent.toFixed(2)}%
                      </div>
                    </TableCell>
                    <TableCell>{asset.marketCap || 'N/A'}</TableCell>
                    <TableCell>{asset.volume}</TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-8 w-8 text-wealth-yellow"
                        onClick={() => handleRemoveFromWatchlist(asset)}
                      >
                        <Star className="h-4 w-4 fill-current" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {watchlist.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} className="h-24 text-center">
                      {t('common.noResults')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Market;
