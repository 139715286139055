import { accountsService, transactionsService } from '@/api/services';
import { Account, AccountData, NetWorthHistory, Transaction } from '@/api/types';
import { AccountsSummary } from '@/components/dashboard/AccountsSummary';
import { AssetsAndLiabilitiesCard } from '@/components/dashboard/AssetsAndLiabilitiesCard';
import { NetWorthCard } from '@/components/dashboard/NetWorthCard';
import { NetWorthTrendCard } from '@/components/dashboard/NetWorthTrendCard';
import { RecentTransactionsCard } from '@/components/dashboard/RecentTransactionsCard';
import { Loading } from '@/components/ui/loading';
import { useLanguage } from '@/i18n/LanguageContext';
import { useEffect, useState } from 'react';

const Dashboard = () => {
  const { t } = useLanguage();
  const [netWorthHistory, setNetWorthHistory] = useState<NetWorthHistory[]>([]);
  const [accountData, setAccountData] = useState<AccountData[]>([]);
  const [assetDistribution, setAssetDistribution] = useState<AccountData[]>([]);
  const [recentTransactions, setRecentTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadDashboardData = async () => {
      setIsLoading(true);
      try {
        // Fetch accounts data
        const accounts = await accountsService.getAccounts();

        // Process accounts data for different views
        const processedAccountData = accounts.map((account) => ({
          name: account.name,
          value: account.balance,
          color: getAccountTypeColor(account.type),
        }));

        // Group by account type
        const accountTypeData = groupByAccountType(accounts);

        // Group by asset type
        const assetTypeData = groupByAssetType(accounts);

        setAccountData(accountTypeData);
        setAssetDistribution(assetTypeData);

        // Fetch recent transactions
        const transactions = await transactionsService.getTransactions();
        setRecentTransactions(transactions.slice(0, 5));

        // Calculate net worth history from accounts
        const netWorthData = calculateNetWorthHistory(accounts);
        setNetWorthHistory(netWorthData);
      } catch (error) {
        console.error('Failed to load dashboard data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadDashboardData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const totalAssets = accountData
    .filter((acc) => !acc.name.toLowerCase().includes('liability'))
    .reduce((acc, curr) => acc + curr.value, 0);
  const totalLiabilities = accountData
    .filter((acc) => acc.name.toLowerCase().includes('liability'))
    .reduce((acc, curr) => acc + curr.value, 0);

  const currentNetWorth = totalAssets - totalLiabilities;
  const previousNetWorth = netWorthHistory[netWorthHistory.length - 2]?.value || currentNetWorth;
  const changeAmount = currentNetWorth - previousNetWorth;
  const changePercentage = (changeAmount / previousNetWorth) * 100;

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">{t('dashboard.title')}</h1>

      <div className="grid gap-6 md:grid-cols-2">
        <NetWorthCard
          totalNetWorth={currentNetWorth}
          changePercentage={changePercentage}
          changeAmount={changeAmount}
          period={t('dashboard.period.thisQuarter')}
        />

        <AssetsAndLiabilitiesCard totalAssets={totalAssets} totalLiabilities={totalLiabilities} />
      </div>

      <NetWorthTrendCard data={netWorthHistory} />

      <div className="grid gap-6 md:grid-cols-2">
        <AccountsSummary accounts={accountData} total={totalAssets} />

        <AccountsSummary accounts={assetDistribution} total={totalAssets} />
      </div>

      <RecentTransactionsCard transactions={recentTransactions} />
    </div>
  );
};

// Helper functions
const getAccountTypeColor = (type: string): string => {
  const colors: Record<string, string> = {
    bank: '#1E5591',
    investment: '#2E7D32',
    'real-estate': '#ED6C02',
    crypto: '#9C27B0',
    alternative: '#1976D2',
    liability: '#D32F2F',
  };
  return colors[type] || '#757575';
};

const groupByAccountType = (accounts: Account[]): AccountData[] => {
  const grouped = accounts.reduce(
    (acc, account) => {
      acc[account.type] = (acc[account.type] || 0) + account.balance;
      return acc;
    },
    {} as Record<string, number>
  );

  return Object.entries(grouped).map(([type, value]) => ({
    name: type.charAt(0).toUpperCase() + type.slice(1).replace('-', ' '),
    value: value as number,
    color: getAccountTypeColor(type),
  }));
};

const groupByAssetType = (accounts: Account[]): AccountData[] => {
  const assetTypes = {
    bank: 'Cash & Equivalents',
    investment: 'Investments',
    'real-estate': 'Real Estate',
    crypto: 'Cryptocurrency',
    alternative: 'Alternative Assets',
  };

  const grouped = accounts.reduce(
    (acc, account) => {
      if (account.type !== 'liability') {
        acc[account.type] = (acc[account.type] || 0) + account.balance;
      }
      return acc;
    },
    {} as Record<string, number>
  );

  return Object.entries(grouped).map(([type, value]) => ({
    name: assetTypes[type as keyof typeof assetTypes] || type,
    value: value as number,
    color: getAccountTypeColor(type),
  }));
};

const calculateNetWorthHistory = (accounts: Account[]): NetWorthHistory[] => {
  // In a real app, this would come from historical data
  // For now, we'll generate some sample data
  const currentNetWorth = accounts.reduce((acc, account) => {
    return acc + (account.type === 'liability' ? -account.balance : account.balance);
  }, 0);

  return [
    { name: 'Jan', value: currentNetWorth * 0.95 },
    { name: 'Feb', value: currentNetWorth * 0.97 },
    { name: 'Mar', value: currentNetWorth * 0.99 },
    { name: 'Apr', value: currentNetWorth * 1.02 },
    { name: 'May', value: currentNetWorth * 1.05 },
    { name: 'Jun', value: currentNetWorth },
  ];
};

export default Dashboard;
