import { preferencesService } from '@/api/services';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Locale, defaultLocale, t } from './i18n';

interface LanguageContextType {
  locale: Locale;
  setLocale: (locale: Locale) => void;
  t: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(defaultLocale);

  useEffect(() => {
    const loadLanguage = async () => {
      // First try to get from localStorage
      const storedPreferences = preferencesService.getPreferencesFromStorage();
      if (storedPreferences?.language) {
        setLocale(storedPreferences.language as Locale);
        return;
      }

      // If not in localStorage, try API call
      try {
        const preferences = await preferencesService.fetchPreferences();
        if (preferences?.language) {
          setLocale(preferences.language as Locale);
          // Save to localStorage for future use
          preferencesService.savePreferencesToStorage(preferences);
        }
      } catch (error) {
        console.error('Failed to fetch language preference:', error);
        // Fall back to default locale
        setLocale(defaultLocale);
      }
    };

    loadLanguage();
  }, []);

  const handleSetLocale = async (newLocale: Locale) => {
    setLocale(newLocale);
    const preferences = preferencesService.getPreferencesFromStorage();
    if (preferences) {
      await preferencesService.updatePreferences({
        ...preferences,
        language: newLocale,
      });
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        locale,
        setLocale: handleSetLocale,
        t: (key: string) => t(key, locale),
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
