import type { Preferences } from '@/api/types';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { useLanguage } from '@/i18n/LanguageContext';
import { Locale } from '@/i18n/i18n.ts';
import { Monitor, Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';
import { useState } from 'react';

interface PreferencesTabProps {
  preferences: Preferences | null;
  onUpdatePreferences: (preferences: Preferences) => Promise<void>;
}

export const PreferencesTab = ({ preferences, onUpdatePreferences }: PreferencesTabProps) => {
  const { t } = useLanguage();
  const { theme: systemTheme, setTheme } = useTheme();
  const { locale, setLocale } = useLanguage();
  const [currentTheme, setCurrentTheme] = useState<string>(preferences?.theme || systemTheme);

  const handleLanguageChange = async (newLanguage: string) => {
    try {
      setLocale(newLanguage as Locale);
      if (preferences) {
        await onUpdatePreferences({
          ...preferences,
          language: newLanguage as Locale,
        });
      }
    } catch (error) {
      console.error('Failed to update language preference:', error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('settings.preferences.title')}</CardTitle>
        <CardDescription>{t('settings.preferences.description')}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <h3 className="text-lg font-medium">{t('settings.preferences.display.title')}</h3>

          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="theme" className="flex flex-col space-y-1">
              <span>{t('settings.preferences.display.theme.label')}</span>
              <span className="text-sm font-normal text-muted-foreground">
                {t('settings.preferences.display.theme.description')}
              </span>
            </Label>
            <div className="flex items-center space-x-2">
              <Button
                variant={currentTheme === 'light' ? 'default' : 'outline'}
                size="icon"
                onClick={() => {
                  setCurrentTheme('light');
                  setTheme('light');
                  if (preferences) {
                    onUpdatePreferences({ ...preferences, theme: 'light' });
                  }
                }}
              >
                <Sun className="h-4 w-4" />
              </Button>
              <Button
                variant={currentTheme === 'dark' ? 'default' : 'outline'}
                size="icon"
                onClick={() => {
                  setCurrentTheme('dark');
                  setTheme('dark');
                  if (preferences) {
                    onUpdatePreferences({ ...preferences, theme: 'dark' });
                  }
                }}
              >
                <Moon className="h-4 w-4" />
              </Button>
              <Button
                variant={currentTheme === 'system' ? 'default' : 'outline'}
                size="icon"
                onClick={() => {
                  setCurrentTheme('system');
                  setTheme('system');
                  if (preferences) {
                    onUpdatePreferences({ ...preferences, theme: 'system' });
                  }
                }}
              >
                <Monitor className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="language" className="flex flex-col space-y-1">
              <span>{t('settings.preferences.display.language.label')}</span>
              <span className="text-sm font-normal text-muted-foreground">
                {t('settings.preferences.display.language.description')}
              </span>
            </Label>
            <Select value={locale} onValueChange={handleLanguageChange}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t('settings.preferences.display.language.placeholder')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="en">English (EN)</SelectItem>
                <SelectItem value="es">Español (ES)</SelectItem>
                <SelectItem value="de">Deutsch (DE)</SelectItem>
                <SelectItem value="ru">Русский (RU)</SelectItem>
                <SelectItem value="fr">Français (FR)</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="currency" className="flex flex-col space-y-1">
              <span>{t('settings.preferences.display.currency.label')}</span>
              <span className="text-sm font-normal text-muted-foreground">
                {t('settings.preferences.display.currency.description')}
              </span>
            </Label>
            <Select
              value={preferences?.currency}
              onValueChange={(value) => {
                if (preferences) {
                  onUpdatePreferences({ ...preferences, currency: value });
                }
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t('settings.preferences.display.currency.placeholder')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="usd">USD ($)</SelectItem>
                <SelectItem value="eur">EUR (€)</SelectItem>
                <SelectItem value="gbp">GBP (£)</SelectItem>
                <SelectItem value="jpy">JPY (¥)</SelectItem>
                <SelectItem value="cad">CAD ($)</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="dateFormat" className="flex flex-col space-y-1">
              <span>{t('settings.preferences.display.dateFormat.label')}</span>
              <span className="text-sm font-normal text-muted-foreground">
                {t('settings.preferences.display.dateFormat.description')}
              </span>
            </Label>
            <Select
              value={preferences?.dateFormat}
              onValueChange={(value) => {
                if (preferences) {
                  onUpdatePreferences({ ...preferences, dateFormat: value });
                }
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue
                  placeholder={t('settings.preferences.display.dateFormat.placeholder')}
                />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="mm/dd/yyyy">MM/DD/YYYY</SelectItem>
                <SelectItem value="dd/mm/yyyy">DD/MM/YYYY</SelectItem>
                <SelectItem value="yyy-mm-dd">YYYY-MM-DD</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <Separator />

        <div className="space-y-4">
          <h3 className="text-lg font-medium">{t('settings.preferences.notifications.title')}</h3>

          <div className="flex items-center space-x-2">
            <Switch
              id="email-notifications"
              checked={preferences?.notifications?.email ?? false}
              onCheckedChange={(checked) => {
                if (preferences) {
                  onUpdatePreferences({
                    ...preferences,
                    notifications: {
                      ...preferences.notifications,
                      email: checked,
                    },
                  });
                }
              }}
            />
            <Label htmlFor="email-notifications" className="flex flex-col space-y-1">
              <span>{t('settings.preferences.notifications.email.label')}</span>
              <span className="text-sm font-normal text-muted-foreground">
                {t('settings.preferences.notifications.email.description')}
              </span>
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              id="balance-alerts"
              checked={preferences?.notifications?.balance ?? false}
              onCheckedChange={(checked) => {
                if (preferences) {
                  onUpdatePreferences({
                    ...preferences,
                    notifications: {
                      ...preferences.notifications,
                      balance: checked,
                    },
                  });
                }
              }}
            />
            <Label htmlFor="balance-alerts" className="flex flex-col space-y-1">
              <span>{t('settings.preferences.notifications.balance.label')}</span>
              <span className="text-sm font-normal text-muted-foreground">
                {t('settings.preferences.notifications.balance.description')}
              </span>
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              id="investment-alerts"
              checked={preferences?.notifications?.investment ?? false}
              onCheckedChange={(checked) => {
                if (preferences) {
                  onUpdatePreferences({
                    ...preferences,
                    notifications: {
                      ...preferences.notifications,
                      investment: checked,
                    },
                  });
                }
              }}
            />
            <Label htmlFor="investment-alerts" className="flex flex-col space-y-1">
              <span>{t('settings.preferences.notifications.investment.label')}</span>
              <span className="text-sm font-normal text-muted-foreground">
                {t('settings.preferences.notifications.investment.description')}
              </span>
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              id="report-notifications"
              checked={preferences?.notifications?.reports ?? false}
              onCheckedChange={(checked) => {
                if (preferences) {
                  onUpdatePreferences({
                    ...preferences,
                    notifications: {
                      ...preferences.notifications,
                      reports: checked,
                    },
                  });
                }
              }}
            />
            <Label htmlFor="report-notifications" className="flex flex-col space-y-1">
              <span>{t('settings.preferences.notifications.reports.label')}</span>
              <span className="text-sm font-normal text-muted-foreground">
                {t('settings.preferences.notifications.reports.description')}
              </span>
            </Label>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
