import { NetWorthHistory } from '@/api/types';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useLanguage } from '@/i18n/LanguageContext';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface NetWorthTrendCardProps {
  data: NetWorthHistory[];
}

export const NetWorthTrendCard = ({ data }: NetWorthTrendCardProps) => {
  const { t } = useLanguage();

  return (
    <Card className="shadow-sm">
      <CardHeader>
        <CardTitle>{t('dashboard.netWorthTrend.title')}</CardTitle>
        <CardDescription>{t('dashboard.netWorthTrend.description')}</CardDescription>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="1y" className="w-full">
          <TabsList className="mb-4">
            <TabsTrigger value="1m">{t('dashboard.period.1m')}</TabsTrigger>
            <TabsTrigger value="3m">{t('dashboard.period.3m')}</TabsTrigger>
            <TabsTrigger value="6m">{t('dashboard.period.6m')}</TabsTrigger>
            <TabsTrigger value="1y">{t('dashboard.period.1y')}</TabsTrigger>
            <TabsTrigger value="all">{t('dashboard.period.all')}</TabsTrigger>
          </TabsList>
          <TabsContent value="1y" className="mt-0">
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={(value) => `$${value / 1000}k`} />
                  <Tooltip formatter={(value) => [`$${value.toLocaleString()}`, 'Net Worth']} />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#1E5591"
                    strokeWidth={2}
                    dot={{ r: 4 }}
                    activeDot={{ r: 6 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};
